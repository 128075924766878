<template>
  <v-container style="max-width: 95%; position: relative;" >
    <v-row>
        <v-col cols="12">
            <v-card
                :loading="loading"
            >
            <v-card-text>
            <template>
  <v-toolbar dense style="">
    <!-- <v-overflow-btn
      :items="dropdown_font"
      label="Select font"
      hide-details
      class="pa-0"
        overflow
    ></v-overflow-btn> -->
    <!-- <v-overflow> -->
      <!-- <v-divider vertical></v-divider> -->
      <v-row>
        <v-col cols="12">
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          placeholder="Search"
          hide-details
          color="orange"
          overflow
          solo
          dense
          style="min-width: 60px;"
      ></v-text-field>
      </v-col>
    </v-row>
    <!-- </v-overflow> -->

    <!-- <template v-if="$vuetify.breakpoint.mdAndUp"> -->

      <!-- <v-overflow-btn
        :items="headers"
        editable
        label="Select size"
        hide-details
      ></v-overflow-btn> -->

      <!-- <v-divider vertical></v-divider> -->

      <!-- &nbsp; -->
      <!-- <v-btn> -->
        &nbsp;
        <template  v-if="$vuetify.breakpoint.mdAndUp">
  <div class="text-center">
    <v-menu
      v-model="menucolumn"
      :close-on-content-click="false"
      :nudge-width="60"
      :nudge-left="60"      
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-view-column</v-icon>Column
        </v-btn>
      </template>

      <v-card style="padding-top:10px;min-width:50px;">
        <!-- &nbsp;<v-icon>mdi-view-column</v-icon>Column -->
        <v-list>
                <ul>
            <div v-for="(item, index) in headers" :key="index">
              <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
              &nbsp;<label :for="item.text">{{item.text}}</label>
            </div>
          </ul>
        </v-list>

      </v-card>
    </v-menu>
  </div>
</template>

<template  v-if="$vuetify.breakpoint.smAndDown">
  <div class="text-center">
    <v-menu
      v-model="menucolumn"
      :close-on-content-click="false"
      :nudge-width="100"
      :nudge-left="100"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-view-column</v-icon>
        </v-btn>
      </template>

      <v-card style="padding-bottom:10px">
        <br>
        &nbsp;&nbsp;
       <b>Column</b>
        <v-list>
                <ul>
            <div v-for="(item, index) in headers" :key="index">
              <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
              &nbsp;<label :for="item.text">{{item.text}}</label>
            </div>
          </ul>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
        <!-- </v-btn> -->

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn-toggle
        v-model="toggle_multiple"
        color="primary"
        dense
        group
        multiple
      > -->
      &nbsp;
        <!-- insert val access supplier download -->
      <!-- <template v-if="$vuetify.breakpoint.mdAndUp">
        
        <v-btn
          text
          color="orange"
          style="background:orange;"
          @click="ExportSupplierInformation()"
        >
          <v-icon>mdi-download-outline</v-icon>
        </v-btn>
        &nbsp;
        <v-btn
          text
          color="orange"
          style="background:orange;"
          @click="uploadfileopen()"
        >
          <v-icon>mdi-upload-outline</v-icon>
        </v-btn>
        </template>

        &nbsp;
        <template v-if="$vuetify.breakpoint.smAndDown">
        
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="orange"
                  dark
                  medium
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in exportimport"
                  :key="index"
                >
                <div v-if="item.linktrigger == 1">
                  <v-list-item-title @click="uploadfileopen()"> <v-icon>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                </div>
                <div v-else>
                  <v-list-item-title @click="ExportSupplierInformation()"> <v-icon>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                </div>
                </v-list-item>
              </v-list>
            </v-menu>
        </template> -->


        <!-- <v-btn
          text
          color="orange"
          style="background:orange;"
        >
          <v-icon>mdi-view-column</v-icon>Column
        </v-btn> -->
       
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
        medium
        dark
          color="orange"
          v-if="$store.getters.valAccess(233)"
          @click="NewBranchInformation()"
        >
          <v-icon>mdi-plus</v-icon>Create
        </v-btn>
        </template>

        <template v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
        medium
        dark
          color="orange"
          elevation="2"
          style="padding-left: 12px;padding-right: 12px;"
          v-if="$store.getters.valAccess(233)"
          @click="NewBranchInformation()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        </template>
        

        

        
        <!-- <v-btn
          :value="3"
          text
        >
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>

        <v-btn
          :value="4"
          text
        >
          <v-icon>mdi-format-color-fill</v-icon>
        </v-btn> -->
      <!-- </v-btn-toggle> -->


    
    <!-- </template> -->
  </v-toolbar>
</template>
                <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                </template>

                <v-card-text>
                  <!-- <div class="text-right">
                    <v-select v-model="selectedHeaders" :items="headers" label="Select Columns" multiple outlined return-object>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} others)</span>
            </template>
         </v-select>
                    <div class="col-sm-12">
                        <v-btn
                            elevation="2"
                            class="mx-2"
                            outlined
                            dark
                            small
                            color="orange">
                            <v-icon small>
                              mdi-download
                            </v-icon>
                            Download
                        </v-btn>
                        <v-btn
                            elevation="2"
                            outlined
                            dark
                            small
                            color="orange"
                            v-if="$store.getters.valAccess(233)"
                            @click="NewBranchInformation()"
                        >
                            <v-icon small>
                                mdi-plus
                            </v-icon>
                            Create
                        </v-btn>
                      </div>
                    </div>

                                    <div class="offset-md-9 col-sm-3">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            outlined
                                            
                                            hide-details
                                            color="orange"
                                        ></v-text-field>
                                      </div> -->
                      <!-- <br> -->
                    <v-data-table
                        :headers="showHeaders"
                        :items="branch"
                        :search="search"
                        :page.sync="page"
                      @page-count="pageCount = $event"
                      :footer-props="{
                      'items-per-page-options':[10],
                      'disable-items-per-page': true,
    }"
                    >

                    <template v-slot:item.branch_image="props">
                        <v-img v-if="props.item.branch_image == null" src="../../assets/images/circle_wirewolf.png" height="100px" width="100px"></v-img>
                        <v-img v-else :src="image_path+props.item.branch_image" height="100px" width="100px"></v-img>
                    </template>

                    <!-- <template v-slot:item.image="props">
                        <v-img
                            contain
                            height="100"
                            width="100"
                            src="../../assets/images/circle_wirewolf.png" 
                            v-if="props.item.branch_image == null"
                            class="py-auto"
                        ></v-img>
                        <v-img
                            v-else
                            contain
                            height="100"
                            width="100"
                            :src="props.item.branch_image" 
                            class="py-auto"
                        ></v-img>
                    </template> -->
                    <!-- <template v-slot:item.branch_name="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.branch_name"
                            @save="TableUpdateBranchInformation(props.item.id, 'branch_name', props.item.branch_name)"
                        >
                        {{ props.item.branch_name }}
                        <template v-slot:input>
                            <v-text-field
                            v-if="$store.getters.valAccess(234)"
                                v-model="props.item.branch_name"
                                label="Edit"
                                single-line
                            ></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </template>
                    <template v-slot:item.branch_address="props">
                        <v-edit-dialog
                            :return-value.sync="props.item.branch_address"
                            @save="TableUpdateBranchInformation(props.item.id, 'branch_address', props.item.branch_address)"
                        >
                        {{ props.item.branch_address }}
                        <template v-slot:input>
                            <v-text-field
                            v-if="$store.getters.valAccess(234)"
                                v-model="props.item.branch_address"
                                label="Edit"
                                single-line
                            ></v-text-field>
                        </template>
                        </v-edit-dialog>
                    </template> -->
                    <template v-slot:item.flag="props">
                        <!-- <v-edit-dialog
                        > -->
                        <v-chip
                            class="ma-2"
                            color="orange"
                            v-if="props.item.flag == 1"
                        >
                            Active
                        </v-chip>
                        <v-chip
                            class="ma-2"
                            color="red"
                            v-else
                        >
                            Inactive
                        </v-chip>
                        <!-- <template v-slot:input>
                            <v-switch
                            v-if="$store.getters.valAccess(234)"
                                v-model="props.item.flag"
                                @change="TableUpdateBranchInformation(props.item.id, 'flag', props.item.flag)"
                            ></v-switch>
                        </template> -->
                        <!-- </v-edit-dialog> -->
                    </template>
                    <template v-slot:item.created_by="props">
                        {{ props.item.created_info.personal_information.lastname }}, {{ props.item.created_info.personal_information.firstname }}
                    </template>
                    <template v-slot:item.action="props">
                        <!-- <v-icon
                            small
                        >
                            mdi-pencil
                        </v-icon> -->
                        <!-- <v-icon
                            small
                            @click="RemoveBranchInformation(props.item.id, props.item)"
                        >
                            mdi-delete
                        </v-icon> -->

                        <v-btn
                            elevation="2"
                              class="mx-2"
                              dark
                              outlined
                              small
                              color="orange"
                              v-if="$store.getters.valAccess(234)"
                              @click="UpdateAllBranch(props.item.id, props.item)"
                          >
                          <v-icon
                                  small
                              >
                                  mdi-file-edit-outline
                              </v-icon>
                              Edit
                          </v-btn>

                        <v-btn
                      elevation="2"
                          class="mx-2"
                          dark
                          small
                          outlined
                          color="red"
                          v-if="$store.getters.valAccess(235)"
                          @click="RemoveBranchInformation(props.item.id, props.item)"
                      >
                          <v-icon small>
                            mdi-delete
                          </v-icon>
                          Delete
                      </v-btn>
                    </template>
                    </v-data-table>
                    <v-pagination
                  color="orange"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
                </v-card-text>
              </v-card-text>
            </v-card>
            
        </v-col>

    </v-row>


    <!-- New Dialog -->


          <!-- edit confirmation dialog -->
    <template>
  <v-row justify="center">
    <v-dialog
      v-model="editconfirmation"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Edit
        </v-card-title>
        <v-card-text>Are you sure you want to update?</v-card-text>
        <!-- <v-card-actions style="align:center"> -->
          <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogEdit(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="confirmUpdate()"
            >
              Confirm
          </v-btn>
        </center>
        <br>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
    <!-- end edit confirmation dialog -->

           <!-- save confirmation dialog -->
    <template>
  <v-row justify="center">
    <v-dialog
      v-model="saveconfirmation"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Save
        </v-card-title>
        <v-card-text>Are you sure you want to save?</v-card-text>
        <!-- <v-card-actions>
          <div class="pa-5">
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            small
            color="orange"
            @click="changeStatusDialogSave(false)"
            >
            Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            small
            color="orange"
            @click="SaveBranchInformation()"
            >
            Save
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        </v-card-actions> -->

        <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogSave(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="SaveBranchInformation()"
            >
            Confirm
          </v-btn>
        </center>
        <br>
      </v-card>
    </v-dialog>
  </v-row>
</template>
    <!-- end save confirmation dialog -->

    <!-- delete confirmation dialog -->
    <template>
  <v-row justify="center">
    <v-dialog
      v-model="confirmation"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete
        </v-card-title>
        <v-card-text>Are you sure you want to delete <b>{{ this.foDelete.data['name'] }}</b>?</v-card-text>
        <!-- <v-card-actions>

          <div class="pa-5">
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            small
            color="orange"
            @click="changeStatusDialog(false)"
            >
            Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            small
            color="orange"
            @click="confirmDelete()"
            >
            Delete
          </v-btn>
        </div>
        <v-spacer></v-spacer> 
        </v-card-actions>-->
        <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialog(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            small
            outlined
            color="orange"
            @click="confirmDelete()"
            >
            Confirm
          </v-btn>
        </center>
        <br>
      </v-card>
    </v-dialog>
  </v-row>
</template>
    <!-- end delete confirmation dialog -->

    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="orange"
        >
          <v-btn
            icon
            dark
            @click="dialog = false, ClearBranch()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add Branch</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <!-- <v-btn
              dark
              text
              id="btn-next" class="btn btn-orange btn-sm p-2" style="font-size: 12px;"
              @click="validate()"
            >
              Save
            </v-btn> -->
          </v-toolbar-items>
        </v-toolbar>

        <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
            <h4>Note: Here you can add another branch information.</h4>
        </v-col>
        

        <v-col sm="12" md="6" offset-md="3">
          <center>
              <div v-if="branchpicture == null || branchpicture == ''">
                  <v-img
                  src="../../assets/images/circle_wirewolf.png"
                  class="pa-2 rounded-circle d-inline-block align-self-stretch"
                  width="250"
                  height="250"
                  style="margin: 0px auto;"
                  ></v-img>   
              </div>
              <div v-else>
                  <v-img
                  v-if="this.url == null"
                  :src=this.image_path+branchpicture
                  class="pa-2 rounded-circle d-inline-block align-self-stretch"
                  width="250"
                  height="250"
                  style="margin: 0px auto;"
                  ></v-img>   
                  <v-img
                  v-else
                  :src=this.url
                  v-model="branchpicture"
                  class="pa-2 rounded-circle d-inline-block align-self-stretch"
                  width="250"
                  height="250"
                  style="margin: 0px auto;"
                  ></v-img>   
              </div>
          </center>
          <!-- <center>
          <v-btn
              @click="handleFileImport"
              elevation="5"
              icon
              :loading="isSelecting" 
              class="btn-camera"
              >
                  <v-icon>mdi-camera</v-icon>
              </v-btn>
              <input 
              ref="uploader" 
              class="d-none" 
              type="file" 
              @change="onFileChanged"
              >
          </center>    -->
        </v-col>
        <!-- <v-col sm="12" md="6" offset-md="3" class="text-center">
            <v-img
                contain
                height="250"
                src="../../assets/images/circle_wirewolf.png" 
            ></v-img>
            <v-btn
                elevation="5"
                icon
                class="btn-camera"
            >
                <v-icon>mdi-camera</v-icon>
            </v-btn>
        </v-col> -->
        <v-col sm="12" md="6" offset-md="3">
            <v-text-field
                color="orange"
                v-model="business_branch_information.branch_name"
                label="Branch Name"
                required
                outlined
                 :counter="50"
                 :rules="[v => !!v || 'Branch Name is required']"
            ></v-text-field>
            <v-text-field
                color="orange"
                v-model="business_branch_information.branch_address"
                label="Branch Address"
                required
                outlined
                 :counter="50"
                 :rules="[v => !!v || 'Branch Address is required']"
            ></v-text-field>
                  <center>
                    <v-col sm="12" md="12">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="validate()"
                        id="btn-next" class="btn btn-success btn-sm p-2" style="font-size: 12px;"
                        >
                        Save
                      </v-btn>
                    </v-col>
                  </center>
        </v-col>
      </v-card>
    </v-dialog>
    </v-form>

    <!-- Dialog Full Screen-->
    <v-dialog
    fullscreen
    v-model="editfullscreendialog"
    hide-overlay
    transition="dialog-bottom-transition"
    >
        <v-card>

            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            >

            <v-toolbar
                dark
                color="orange"
            >
                <v-btn icon dark @click="CloseDialogEditFullScreen(), SetNewBranchInformation()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Edit Branch</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                </v-toolbar-items>
            </v-toolbar>

            <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
            <h4>Note: Here you can edit branch information.</h4>
        </v-col>
        

        <v-col sm="12" md="6" offset-md="3">
          <center>
              <div v-if="branchpicture == null || branchpicture == ''">
                  <v-img
                  src="../../assets/images/circle_wirewolf.png"
                  class="pa-2 rounded-circle d-inline-block align-self-stretch"
                  width="250"
                  height="250"
                  style="margin: 0px auto;"
                  ></v-img>   
              </div>
              <div v-else>
                  <v-img
                  v-if="this.url == null"
                  :src=this.image_path+branchpicture
                  class="pa-2 rounded-circle d-inline-block align-self-stretch"
                  width="250"
                  height="250"
                  style="margin: 0px auto;"
                  ></v-img>   
                  <v-img
                  v-else
                  :src=this.url
                  v-model="branchpicture"
                  class="pa-2 rounded-circle d-inline-block align-self-stretch"
                  width="250"
                  height="250"
                  style="margin: 0px auto;"
                  ></v-img>   
              </div>
          </center>
          <!-- <center>
          <v-btn
              @click="handleFileImport"
              elevation="5"
              icon
              :loading="isSelecting" 
              class="btn-camera"
              >
                  <v-icon>mdi-camera</v-icon>
              </v-btn>
              <input 
              ref="uploader" 
              class="d-none" 
              type="file" 
              @change="onFileChanged"
              >
          </center>    -->
        </v-col>
        <!-- <v-col sm="12" md="6" offset-md="3" class="text-center">
            <v-img
                contain
                height="250"
                src="../../assets/images/circle_wirewolf.png" 
            ></v-img>
            <v-btn
                elevation="5"
                icon
                class="btn-camera"
            >
                <v-icon>mdi-camera</v-icon>
            </v-btn>
        </v-col> -->
        <v-col sm="12" md="6" offset-md="3">
            <v-switch
                v-model="business_branch_information.flag"
                inset
                :label="activeinactive"
                color="orange"
                @change="activeinactivetrigger()"
            ></v-switch>
            <v-text-field
                color="orange"
                v-model="business_branch_information.branch_name"
                label="Branch Name"
                required
                outlined
                 :counter="50"
                 :rules="[v => !!v || 'Branch Name is required']"
            ></v-text-field>
            <v-text-field
                color="orange"
                v-model="business_branch_information.branch_address"
                label="Branch Address"
                required
                outlined
                 :counter="50"
                 :rules="[v => !!v || 'Branch Address is required']"
            ></v-text-field>
            <center>
                    <v-col sm="12" md="6">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="valid == false ? validationfalse() : changeStatusDialogEdit(true)"
                        >
                        Update
                      </v-btn>
                    </v-col>
                    </center>
        </v-col>
            </v-form>
        </v-card>
    </v-dialog>

  </v-container>
    
</template>

<script>

  import branchservices from '../services/branchservices';

  export default {
    name: 'Branch',
    data: () => ({
      hrisdata: 0,
      activeinactive: '',
      editfullscreendialog: false,
      isSelecting: false,
      url: null,
      image_path: [],
      branchpicture: '',
      menucolumn : '',
      search: '',
      page: 1,
        pageCount: 0,
        dialog: false,
        loading: false,
        selectedHeaders: [],
        headers:[],
        headersMap: [
          { text: 'Image',sortable: false, value: 'branch_image' },
          { text: 'Branch',sortable: true, value: 'branch_name' },
          { text: 'Branch Address',sortable: false, value: 'branch_address' , width: '230'},
          { text: 'Status',sortable: false, value: 'flag' },
          { text: 'Created By',sortable: false, value: 'created_by' },
          { text: 'Action',sortable: false, value: 'action' , width: '230'}
        ],
        branch: [],
        business_branch_information: {
            parent_user_id: 0,
            business_id: 0,
            branch_name: '',
            branch_address: '',
            created_by: 0,
            flag: ''
        },
        valid: true,
        nameRules: [
        v => !!v || 'Branch Name is required',
        // v => (v && v.length >= 2) || 'Name must be more than 1 characters',
        ],
        confirmation: false,
        saveconfirmation: false,
        editconfirmation: false,
        foDelete: {
            id: 0,
            data: ''
        }
    }),
    created(){
      this.hrisdata = localStorage.getItem('is_hris')
        if(this.hrisdata == 1){
            this.$store.state.header = false;
            this.$store.state.footer = false;
        }else{
            this.$store.state.header = true;
            this.$store.state.footer = true;
        }
        this.$store.state.snackbar.color = 'orange';
        this.FetchBranchInformation();
        this.SetNewBranchInformation();
        this.headers = Object.values(this.headersMap);
        this.selectedHeaders = this.headers;
    },
    mounted(){
        this.$store.state.title = "Branch Information";
        // console.log(this.$store.getters.getBusinessID);
    },
    computed: {
      //Done to get the ordered headers
      showHeaders () {
        return this.headers.filter(s => this.selectedHeaders.includes(s));
      }
    },
    methods: {
        changeStatusDialog(status){
            this.confirmation = status;
        },
        changeStatusDialogSave(status){
            this.saveconfirmation = status;
        },
        changeStatusDialogEdit(status){
            this.editconfirmation = status;
            this.FetchBranchInformation();
        },
        FetchBranchInformation(){

            let payload = {
                user_id: this.$store.getters.getParentID
                // user_id: this.$store.getters.fetchUserInfo.account_information.is_parent == 1 ? this.$store.getters.fetchUserInfo.account_information.id : this.$store.getters.fetchUserInfo.account_information.parent_id
            };

            branchservices.fetchBranchInformation(payload,  this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    this.branch = response.data.result;
                    this.image_path = response.data.image_path;
                }
            }).catch(error => console.log(error));

        },
        confirmDelete(){
            this.branch.splice(this.branch.indexOf(this.foDelete['data']), 1);

            let payload = {
                id: this.foDelete['id']
            };

            branchservices.removeBranchInformation(payload, this.$store.getters.getAccessToken).catch(error => console.log(error));

            this.$store.state.snackbar.message = 'Branch information has been removed.';
            this.$store.state.snackbar.show = true;
            this.confirmation = false
            this.$store.state.snackbar.colors = 'xgreen';
            this.$store.state.snackbar.icon = "mdi-check-circle-outline";                                  

        },
        RemoveBranchInformation(id, data){

            this.foDelete = {
                id: id,
                data: data
            }
            this.confirmation = true
        },
        confirmUpdate(){
          this.business_branch_information.flag == true ? this.business_branch_information.flag = 1 : this.business_branch_information.flag = 0;
          console.log( this.business_branch_information.flag );
          let val = this.$refs.form.validate();

          this.valid = true;

          if(val){

            const formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('id', this.forAllUpdate.id);
            formData.append('business_branch_information', JSON.stringify(this.business_branch_information));

            // let payload = {
            //     id: this.foUpdate['id'],
            //     name: this.foUpdate['name'],
            //     value: this.foUpdate['value'],
            // }
            branchservices.UpdateBranchInformation(formData,  this.$store.getters.getAccessToken).catch(error => console.log(error));
            this.$store.state.snackbar.message = 'Branch information has been updated.';
            
            this.FetchBranchInformation();
            this.ClearBranch();
            this.$store.state.snackbar.show = true;
            this.$store.state.snackbar.colors = 'xgreen';
            this.$store.state.snackbar.icon = "mdi-check-circle-outline";                                  
          }
            
            this.editfullscreendialog = false
            this.editconfirmation = false
        },

        TableUpdateBranchInformation(id, name, value){

            this.foUpdate = {
                id: id,
                name: name,
                value: value
            }
            this.editconfirmation = true

        },
        NewBranchInformation(){
            this.dialog = true;
        },
        SetNewBranchInformation(){

            this.business_branch_information = {
                parent_user_id: this.$store.getters.getParentID,
                // business_id: this.$store.getters.fetchUserInfo.account_information.is_parent == 1 ? this.$store.getters.fetchUserInfo.account_information.parent_business_information.id : this.$store.getters.fetchUserInfo.account_information.child_business_information.id,
                business_id: this.$store.getters.getBusinessID,
                branch_name: '',
                branch_address: '',
                created_by: this.$store.getters.fetchUserInfo.account_information.id
            };

        },
        validate(){

            let val = this.$refs.form.validate();

            if(val){
                this.confirmSave();
            }
        },
        confirmSave(){
            this.saveconfirmation = true
        },
        SaveBranchInformation(){

            // let payload = {
            //     business_branch_information: JSON.stringify(this.business_branch_information)
            // };

            const formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('business_branch_information', JSON.stringify(this.business_branch_information));

            branchservices.saveBranchInformation(formData, this.$store.getters.fetchUserInfo.access_token).then(response => {
                if(response.data.success){
                    this.dialog = false;
                    this.SetNewBranchInformation();
                    this.FetchBranchInformation();
                    this.ClearBranch();
                    this.$store.state.snackbar.message = response.data.message;
                    this.$store.state.snackbar.show = true;
                    this.saveconfirmation = false
                    this.$store.state.snackbar.colors = 'xgreen';
                    this.$store.state.snackbar.icon = "mdi-check-circle-outline";                      
                }else{
                    this.$store.state.snackbar.colors = 'xred';
                      this.$store.state.snackbar.icon = "mdi-alert-outline";                      
                  }
            }).catch(error => console.log(error));

        },
        handleFileImport() {
              this.isSelecting = true;

              // After obtaining the focus when closing the FilePicker, return the button state to normal
              window.addEventListener('focus', () => {
                  this.isSelecting = false
              }, { once: true });
              
              // Trigger click on the FileInput
              this.$refs.uploader.click();
          },
          onFileChanged(e) {
              this.selectedFile = e.target.files[0];
              if(!this.selectedFile){
                  this.url=null;
                  return;
              } 
              this.url= URL.createObjectURL(this.selectedFile);
              this.branchpicture = 1;
              // console.log(this.url)
              // Do whatever you need with the file, liek reading it with FileReader
          },
          UpdateAllBranch(id, item){
            console.log(id, item)
            this.branchpicture = item.branch_image
            this.selectedFile = item.branch_image
            this.business_branch_information.branch_name = item.branch_name
            this.business_branch_information.branch_address = item.branch_address
            this.business_branch_information.flag = item.flag
            this.activeinactivetrigger();
            this.forAllUpdate = {
                id: id,
            }
            this.editfullscreendialog = true
        },
        CloseDialogEditFullScreen(){
            this.editfullscreendialog = false
            this.ClearBranch();
        },
        validationfalse(){
            this.$refs.form.validate()
        },
        ClearBranch(){
            this.branchpicture = ''
            this.selectedFile = ''
            this.business_branch_information.branch_name = ''
            this.business_branch_information.branch_address = ''
            this.business_branch_information.flag = ''
        },
        activeinactivetrigger(){
            if(this.business_branch_information.flag == 1){
                this.activeinactive = 'Active'
            }else{
                this.activeinactive = 'Inactive'
            }
        },
    }
  }
</script>

<style scoped>
  input[type=checkbox] {
  accent-color: orange;
}
    .btn-camera{
        margin-top: -25px;
        position: absolute;
        margin-left: -18px;
        background-color: white;
    }
</style>
