<template>
    <v-container style="max-width: 95%; position: relative;" >
    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text>

<template>
    <v-toolbar dense style="">
        <v-row>
            <v-col cols="12">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            placeholder="Search"
            hide-details
            color="orange"
            overflow
            solo
            dense
            style="min-width: 60px;"
        ></v-text-field>
        </v-col>
        </v-row>

            &nbsp;
    <template  v-if="$vuetify.breakpoint.mdAndUp">
    <div class="text-center">
        <v-menu
        v-model="menucolumn"
        :close-on-content-click="false"
        :nudge-width="60"
        :nudge-left="60"      
        offset-y
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="orange"
            dark
            v-bind="attrs"
            v-on="on"
            >
            <v-icon>mdi-view-column</v-icon>Column
            </v-btn>
        </template>

        <v-card style="padding-top:10px;min-width:50px;">
            <v-list>
                    <ul>
                <div v-for="(item, index) in headers" :key="index">
                <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
                &nbsp;<label :for="item.text">{{item.text}}</label>
                </div>
            </ul>
            </v-list>

        </v-card>
        </v-menu>
    </div>
    </template>

    <template  v-if="$vuetify.breakpoint.smAndDown">
    <div class="text-center">
        <v-menu
        v-model="menucolumn"
        :close-on-content-click="false"
        :nudge-width="100"
        :nudge-left="100"
        offset-y
        >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="orange"
            dark
            v-bind="attrs"
            v-on="on"
            >
            <v-icon>mdi-view-column</v-icon>
            </v-btn>
        </template>

        <v-card style="padding-bottom:10px">
            <br>
            &nbsp; &nbsp;
        <b>Column</b>
            <v-list>
                    <ul>
                <div v-for="(item, index) in headers" :key="index">
                <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
                &nbsp;<label :for="item.text">{{item.text}}</label>
                </div>
            </ul>
            </v-list>
        </v-card>
        </v-menu>
    </div>
</template>

    &nbsp;
    
    <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
        medium
        dark
        color="orange"
        elevation="2"
        @click="NewAccountabilityField()" 
        v-if="$store.getters.valAccess(79)"
        >
        <v-icon>mdi-plus</v-icon>Create
        </v-btn>
        </template>

        <template v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
        medium
        dark
        color="orange"
        elevation="2"
        style="padding-left: 12px;padding-right: 12px;"
        @click="NewAccountabilityField()" 
        v-if="$store.getters.valAccess(79)"
        >
        <v-icon>mdi-plus</v-icon>
        </v-btn>
        </template>
        
</v-toolbar>
</template>

                    <!-- <div class="text-right">
                        <v-select v-model="selectedHeaders" :items="headers" label="Select Columns" multiple outlined return-object>
            <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 2">
                <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 2" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} others)</span>
            </template>
        </v-select>                        
                        <div class="col-sm-12">
                        <v-btn elevation="2" outlined dark small color="orange" @click="NewAccountabilityField()"  v-if="$store.getters.valAccess(79)">
                            <v-icon small>
                                mdi-plus
                            </v-icon>
                            Create
                        </v-btn>
                    </div>
                    </div>
                    <div class="offset-md-9 col-sm-3">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            outlined
                                            hide-details
                                            color="orangeligthen-2"
                                        ></v-text-field>
                            </div> -->
                            <br>
                    <v-data-table
                        :headers="showHeaders"
                        :items="accountabilityfielddata"
                        :search="search"
                        :page.sync="page"
                    @page-count="pageCount = $event"
                    :footer-props="{
                    'items-per-page-options':[10],
                    'disable-items-per-page': true,
    }"
                    >
                            
                            <template v-slot:item.created_by="props">
                                {{ props.item.created_info != null ? props.item.created_info.personal_information.lastname + ' , ' + props.item.created_info.personal_information.firstname : 'No Data' }}
                            </template>

                            <template v-slot:item.action="props">
                                <template>
                                    <v-row>
                                    <v-btn
                                        elevation="2"
                                            class="mx-2"
                                            dark
                                            outlined
                                            small
                                            color="orange"
                                            @click="UpdateAllAccountabilityField(props.item.id, props.item)"
                                        >
                                            <!-- v-if="$store.getters.valAccess(80)" -->
                                        <v-icon
                                                small
                                                @click="UpdateAllAccountabilityField(props.item.id, props.item)"
                                            >
                                                mdi-file-edit-outline
                                            </v-icon>
                                            Edit
                                        </v-btn>   
                                        <v-btn
                                        elevation="2"
                                            class="mx-2"
                                            dark
                                            outlined
                                            small
                                            color="red"
                                            @click="DeleteAccountabilityField(props.item.id, props.item.name)"
                                        >
                                            <!-- v-if="$store.getters.valAccess(81)" -->
                                        <v-icon
                                                small
                                                @click="DeleteAccountabilityField(props.item.id, props.item.name)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                            Delete
                                        </v-btn>       
                                    </v-row>
                                </template>  
                            </template>
                    </v-data-table>
                    <v-pagination
                    color="orange"
                    v-model="page"
                    :length="pageCount"
                    ></v-pagination>
                </v-card-text>

            </v-card>
            
        </v-col>

    </v-row>

        <!-- Dialog Full Screen-->
        <v-dialog
        v-model="fullscreendialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-toolbar
                    dark
                    color="orange"
                >
                    <v-btn icon dark @click="CloseDialogFullScreen()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Add Accountability Field</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
                    <h4>Note: Here you can create accountability field</h4>
                </v-col>

                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="accountability_field.name"
                        label="Name"
                        color="orange"
                        required
                        outlined
                        :rules="[v => !!v || 'Name is required']"
                    ></v-text-field>
                </v-col>

                <v-col sm="12" md="6" offset-md="3">
                    <v-combobox
                        label="Accountability Type"
                        color="orange"
                        outlined
                        :items="accountabilitytypedata"
                        :rules="[v => !!v || 'Accountability Type is required']"
                        v-model="accountability_field.accountability_type_id"
                    ></v-combobox>
                </v-col>

                <center>
                    <v-col sm="12" md="6">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="valid == false ? validationfalse() : changeStatusDialogSave(true)"
                        >
                        Save
                    </v-btn>
                    </v-col>
                    </center>
                </v-form>
            </v-card>
    </v-dialog>

    <!-- Dialog Pop Up Box -->




    <!-- Dialog Full Screen Edit-->
    <v-dialog
        v-model="editfullscreendialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-toolbar
                    dark
                    color="orange"
                >
                    <v-btn icon dark @click="CloseDialogEditFullScreen()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Edit Accountability Field</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
                    <h4>Note: Here you can edit accountability field</h4>
                </v-col>

                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="accountability_field.name"
                        label="Name"
                        color="orange"
                        required
                        outlined
                        :rules="[v => !!v || 'Name is required']"
                    ></v-text-field>
                </v-col>

                <v-col sm="12" md="6" offset-md="3">
                    <v-combobox
                        label="Accountability Type"
                        color="orange"
                        outlined
                        :items="accountabilitytypedata"
                        :rules="[v => !!v || 'Accountability Type is required']"
                        v-model="accountability_field.accountability_type_id"
                    ></v-combobox>
                </v-col>

                <center>
                    <v-col sm="12" md="6">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="valid == false ? validationfalse() : changeStatusDialogEdit(true)"
                        >
                        Update
                    </v-btn>
                    </v-col>
                    </center>
                </v-form>
            </v-card>
    </v-dialog>

    <!-- Dialog Pop Up Box -->


    <!-- save confirmation dialog -->
    <template>
<v-row justify="center">
    <v-dialog
    v-model="saveconfirmation"
    persistent
    max-width="290"
    >
    <v-card>
        <v-card-title class="text-h5">
        Save
        </v-card-title>
        <v-card-text>Are you sure you want to save?</v-card-text>
        <center>
        <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogSave(false)"
            >
                Cancel
        </v-btn>
        <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="SaveAccountabilityField()"
            >
            Confirm
        </v-btn>
        </center>
        <br>

    </v-card>
    </v-dialog>
</v-row>
</template>



    <!-- delete confirmation dialog -->
    <template>
<v-row justify="center">
    <v-dialog
    v-model="deleteconfirmation"
    persistent
    max-width="290"
    >
    <v-card>
        <v-card-title class="text-h5">
        Delete
        </v-card-title>
        <v-card-text>Are you sure you want to delete {{ fordeletename }}?</v-card-text>
        <center>
        <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogDelete(false)"
            >
                Cancel
        </v-btn>
        <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="confirmDelete()"
            >
            Confirm
        </v-btn>
        </center>
        <br>
    </v-card>
    </v-dialog>
</v-row>
</template>
    <!-- end delete confirmation dialog -->

    <v-row justify="center">
          <v-dialog
            v-model="editconfirmation"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title class="text-h5">
                Edit
              </v-card-title>
              <v-card-text>Are you sure you want to update?</v-card-text>
              <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogEdit(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="confirmUpdate()"
            >
            Confirm
          </v-btn>
        </center>
        <br>    
            </v-card>
          </v-dialog>
        </v-row>
        
    </v-container>
    
</template>
  
<script>

import accountabilityfieldservices from '../services/accountabilityfieldservices';
import accountabilitytypeservices from '../services/accountabilitytypeservices';

export default {
    name: 'AccountabilityField',
    data: () => ({
        menucolumn: false,
        exportimport: [{ text: 'Upload', icon: 'mdi-upload-outline', linktrigger: 1 },
        { text: 'Download', icon: 'mdi-download-outline', linktrigger: 2 }],
        classito: 'd-inline-block text-truncate',
        search: '',
        page: 1,
        pageCount: 0,
        valid: false,
        saveconfirmation: false,
        deleteconfirmation: false,
        editfullscreendialog: false,
        editconfirmation : false,
        fordeletename: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dialog: false,
        fullscreendialog: false,
        accountability_field: {
            name: '',
            accountability_type_id: [],
        },
        headers:[],
        selectedHeaders: [],
        headersMap: [
            { text: 'Name',sortable: true, value: 'name' },
            { text: 'Accountability Type',sortable: false, value: 'accountability_type_info.name' },
            { text: 'Created By',sortable: false, value: 'created_by' },
            { text: 'Action',sortable: false, value: 'action',width: '235px' }
            ],
        accountabilityfielddata: [],
        accountabilitytypedata: [],
        }),
        created(){
            this.$store.state.header = false;
            this.$store.state.footer = false;
            this.$store.state.snackbar.color = 'orange';
            this.FetchAllAccountabilityField();
            this.FetchAllAccountabilityType();
            this.headers = Object.values(this.headersMap);
            this.selectedHeaders = this.headers;          
        },
        mounted(){
            this.$store.state.title = "Accountability Field";
        },
        computed: {
        showHeaders () {
                return this.headers.filter(s => this.selectedHeaders.includes(s));
            }
        },      
        methods: {
        FetchAllAccountabilityField(){
            let payload = {
                parent_id: this.$store.getters.getParentID
            };
            accountabilityfieldservices.FetchAllAccountabilityField(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    this.accountabilityfielddata = response.data.data;
                }
            }).catch(error => console.log(error));
        },
        UpdateAllAccountabilityField(id, item){
            console.log(id, item);
            this.accountability_field.name = item.name
            this.accountability_field.accountability_type_id = { text: item.accountability_type_info.name, value: item.accountability_type_info.id }
            this.forAllUpdate = {
                id: id,
                created_by: item.created_by
            }

            this.editfullscreendialog = true
        },
        confirmUpdate(){
            let val = this.$refs.form.validate();

            if(val){
            let payload = {
                id: this.forAllUpdate['id'],
                name: this.accountability_field.name,
                accountability_type_id : this.accountability_field.accountability_type_id.value,
                created_by: this.forAllUpdate['created_by'],
                parent_user_id: this.$store.getters.getParentID,
                flag: "1",
            }
            console.log(payload)
            accountabilityfieldservices.UpdateAccountabilityField(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    this.$store.state.snackbar.message = 'Accountability field has been updated.';
                    this.$store.state.snackbar.show = true;
                    this.editfullscreendialog = false
                    this.editconfirmation = false
                    this.ClearAccountabilityField()
                    this.$refs.form.resetValidation();
                    this.$store.state.snackbar.colors = 'xgreen';
                    this.$store.state.snackbar.icon = "mdi-check-circle-outline";                      
                }
                }).catch(error =>{
                this.$store.state.snackbar.colors = 'xred';
                this.$store.state.snackbar.icon = "mdi-alert-outline";   
                this.errorDAW = error.response.data.message
                this.$store.state.snackbar.message = error.response.data.message;
                this.$store.state.snackbar.show = true;
            });
            }
            this.FetchAllAccountabilityField();
        },
        NewAccountabilityField(){
            this.fullscreendialog = true
        },
        SaveAccountabilityField(){

            let val = this.$refs.form.validate();

            if(val){
                let payload = {
                    parent_user_id: this.$store.getters.getParentID,
                    name: this.accountability_field['name'],
                    accountability_type_id : this.accountability_field.accountability_type_id.value,
                    created_by: this.$store.getters.getUserID,
                };
                console.log(payload);
                accountabilityfieldservices.SaveAccountabilityField(payload, this.$store.getters.getAccessToken).then(response => {
                    if(response.data.success){
                        this.fullscreendialog = false;
                        this.$store.state.snackbar.message = 'Accountability field has been created.';
                        this.$store.state.snackbar.show = true;
                        this.FetchAllAccountabilityField();
                        this.ClearAccountabilityField();
                        this.$refs.form.resetValidation();
                        this.$store.state.snackbar.colors = 'xgreen';
                    this.$store.state.snackbar.icon = "mdi-check-circle-outline";                      
                    }
                }).catch(error =>{ 
                    console.log(error.response.data.errors)
                    this.$store.state.snackbar.message = error.response.data.message;
                    this.$store.state.snackbar.show = true;
                    this.$store.state.snackbar.colors = 'xred';
                        this.$store.state.snackbar.icon = "mdi-alert-outline";     
                });
            }
            this.saveconfirmation = false;
        }, 
        CloseDialogFullScreen(){
            this.ClearAccountabilityField();
            this.fullscreendialog = false
        },
        CloseDialogEditFullScreen(){
            this.ClearAccountabilityField();
            this.editfullscreendialog = false
        },
        ClearAccountabilityField(){
            this.accountability_field['name'] = ''
            this.accountability_field['accountability_type_id'] = ''
        },
        DeleteAccountabilityField(id, name){
            this.fordeletename = name
            this.forDelete = {
                id: id
            }
            this.deleteconfirmation = true;
        },
        confirmDelete(){
            let payload = {
                id: this.forDelete['id']
            }
            console.log(payload);
            accountabilityfieldservices.DeleteAccountabilityField(payload, this.$store.getters.getAccessToken).catch(error =>{
                this.errorDAW = error.response.data.message
                this.$store.state.snackbar.message = error.response.data.message;
                this.$store.state.snackbar.show = true;
            });

            this.$store.state.snackbar.message = 'Accountability field has been deleted.';
            this.$store.state.snackbar.show = true;
            this.deleteconfirmation = false;
            this.FetchAllAccountabilityField();
            this.$store.state.snackbar.colors = 'xgreen';
            this.$store.state.snackbar.icon = "mdi-check-circle-outline";                                  
        },
        changeStatusDialogSave(status){
            this.saveconfirmation = status;
        },
        changeStatusDialogDelete(status){
            this.deleteconfirmation = status;
            this.FetchAllAccountabilityField();
        },
        changeStatusDialogEdit(status){
            this.editconfirmation = status;
            this.FetchAllAccountabilityField();
        },
        validationfalse(){
            this.$refs.form.validate()
        },
        FetchAllAccountabilityType(){
            let payload = {
                parent_id: this.$store.getters.getParentID
            };
            accountabilitytypeservices.FetchAllAccountabilityType(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    response.data.data.forEach((value) => {
                        this.accountabilitytypedata.push({
                            text: value.name,
                            value: value.id
                        });
                    });
                }
            }).catch(error => console.log(error));
        },
    }
}
</script>
  
<style scoped>
    input[type=checkbox] {
        accent-color: orange;
    }
</style>