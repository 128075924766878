<template>
    <v-container style="max-width: 95%; position: relative;" >
      <v-row>
          <v-col cols="12">
              <v-card>
                  <v-card-text>

<template>
  <v-toolbar dense style="">
    <!-- <v-overflow-btn
      :items="dropdown_font"
      label="Select font"
      hide-details
      class="pa-0"
        overflow
    ></v-overflow-btn> -->
    <!-- <v-overflow> -->
      <!-- <v-divider vertical></v-divider> -->
      <v-row>
        <v-col cols="12">
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          placeholder="Search"
          hide-details
          color="orange"
          overflow
          solo
          dense
          style="min-width: 60px;"
      ></v-text-field>
      </v-col>
    </v-row>
    <!-- </v-overflow> -->

    <!-- <template v-if="$vuetify.breakpoint.mdAndUp"> -->

      <!-- <v-overflow-btn
        :items="headers"
        editable
        label="Select size"
        hide-details
      ></v-overflow-btn> -->

      <!-- <v-divider vertical></v-divider> -->

      <!-- &nbsp; -->
      <!-- <v-btn> -->
        &nbsp;
<template  v-if="$vuetify.breakpoint.mdAndUp">
  <div class="text-center">
    <v-menu
      v-model="menucolumn"
      :close-on-content-click="false"
      :nudge-width="60"
      :nudge-left="60"      
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-view-column</v-icon>Column
        </v-btn>
      </template>

      <v-card style="padding-top:10px;min-width:50px;">
        <!-- &nbsp;<v-icon>mdi-view-column</v-icon>Column -->
        <v-list>
                <ul>
            <div v-for="(item, index) in headers" :key="index">
              <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
              &nbsp;<label :for="item.text">{{item.text}}</label>
            </div>
          </ul>
        </v-list>

      </v-card>
    </v-menu>
  </div>
</template>

<template  v-if="$vuetify.breakpoint.smAndDown">
  <div class="text-center">
    <v-menu
      v-model="menucolumn"
      :close-on-content-click="false"
      :nudge-width="100"
      :nudge-left="100"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-view-column</v-icon>
        </v-btn>
      </template>

      <v-card style="padding-bottom:10px">
        <br>
        &nbsp; &nbsp;
       <b>Column</b>
        <v-list>
                <ul>
            <div v-for="(item, index) in headers" :key="index">
              <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
              &nbsp;<label :for="item.text">{{item.text}}</label>
            </div>
          </ul>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
        <!-- </v-btn> -->

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn-toggle
        v-model="toggle_multiple"
        color="primary"
        dense
        group
        multiple
      > -->
      &nbsp;
        <!-- insert val access supplier download -->
      <!-- <template v-if="$vuetify.breakpoint.mdAndUp">
        
        <v-btn
          text
          color="orange"
          style="border: thin solid orange;"
          @click="ExportSupplierInformation()"
        >
          <v-icon>mdi-download-outline</v-icon>
        </v-btn>
        &nbsp;
        <v-btn
          text
          color="orange"
          style="border: thin solid orange;"
          @click="uploadfileopen()"
        >
          <v-icon>mdi-upload-outline</v-icon>
        </v-btn>
        </template>

        &nbsp;
        <template v-if="$vuetify.breakpoint.smAndDown">
        
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="orange"
                  dark
                  medium
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in exportimport"
                  :key="index"
                >
                <div v-if="item.linktrigger == 1">
                  <v-list-item-title @click="uploadfileopen()"> <v-icon>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                </div>
                <div v-else>
                  <v-list-item-title @click="ExportSupplierInformation()"> <v-icon>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                </div>
                </v-list-item>
              </v-list>
            </v-menu>
        </template> -->


        <!-- <v-btn
          text
          color="orange"
          style="border: thin solid orange;"
        >
          <v-icon>mdi-view-column</v-icon>Column
        </v-btn> -->
       
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
        medium
        dark
          color="orange"
          elevation="2"
          @click="NewLeaveType()" 
          v-if="$store.getters.valAccess(79)"
        >
          <v-icon>mdi-plus</v-icon>Create
        </v-btn>
        </template>

        <template v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
        medium
        dark
          color="orange"
          elevation="2"
          style="padding-left: 12px;padding-right: 12px;"
          @click="NewLeaveType()" 
          v-if="$store.getters.valAccess(79)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        </template>
        

        

        
        <!-- <v-btn
          :value="3"
          text
        >
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>

        <v-btn
          :value="4"
          text
        >
          <v-icon>mdi-format-color-fill</v-icon>
        </v-btn> -->
      <!-- </v-btn-toggle> -->


    
    <!-- </template> -->
  </v-toolbar>
</template>






                      <!-- <div class="text-right">
                        <v-select v-model="selectedHeaders" :items="headers" label="Select Columns" multiple outlined return-object>
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index < 2">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 2" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} others)</span>
            </template>
         </v-select>                        
                        <div class="col-sm-12">
                          <v-btn elevation="2" outlined dark small color="orange" @click="NewLeaveType()"  v-if="$store.getters.valAccess(79)">
                              <v-icon small>
                                  mdi-plus
                              </v-icon>
                              Create
                          </v-btn>
                      </div>
                    </div>
                      <div class="offset-md-9 col-sm-3">
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            outlined
                                            hide-details
                                            color="orangeligthen-2"
                                        ></v-text-field>
                            </div> -->
                            <br>
                      <v-data-table
                          :headers="showHeaders"
                          :items="leavetypedata"
                          :search="search"
                          :page.sync="page"
                      @page-count="pageCount = $event"
                      :footer-props="{
                      'items-per-page-options':[10],
                      'disable-items-per-page': true,
    }"
                      >
                            <!-- <template v-slot:item.name="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.name"
                                    @save="UpdateLeaveType(props.item.id, props.item.name, props.item.code, props.item.description, props.item.created_by)"
                                >
                                {{ props.item.name }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.name"
                                            label="Edit"
                                            color="orange"
                                            single-line
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.code="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.code"
                                    @save="UpdateLeaveType(props.item.id, props.item.name, props.item.code, props.item.description, props.item.created_by)"
                                >
                                {{ props.item.code }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.code"
                                            label="Edit"
                                            color="orange"
                                            single-line
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.description="props">
                                <v-edit-dialog
                                    :return-value.sync="props.item.description"
                                    @save="UpdateLeaveType(props.item.id, props.item.name, props.item.code, props.item.description, props.item.created_by)"
                                >
                                {{ props.item.description }}
                                    <template v-slot:input>
                                        <v-text-field
                                            v-model="props.item.description"
                                            label="Edit"
                                            color="orange"
                                            single-line
                                        ></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template> -->

                            <template v-slot:item.created_by="props">
                                {{ props.item.created_info != null ? props.item.created_info.personal_information.lastname + ' , ' + props.item.created_info.personal_information.firstname : 'No Data' }}
                            </template>

                            <template v-slot:item.description="props">
                                <div @click="inlineblock()">
                                    <span
                                    :class="classito"
                                    style="max-width: 300px;"
                                    >
                                    {{ props.item.description}}
                                    </span>
                                </div>
                            </template>

                            <template v-slot:item.action="props">
                                <template v-if="$vuetify.breakpoint.xsOnly">
                                    <v-row>
                                      <v-btn
                                        elevation="2"
                                            class="mx-2"
                                            dark
                                            outlined
                                            small
                                            color="orange"
                                            v-if="$store.getters.valAccess(80) && props.item.name != 'Offset Leave'"
                                            @click="UpdateAllLeaveType(props.item.id, props.item)"
                                        >
                                        <v-icon
                                                small
                                                @click="UpdateAllLeaveType(props.item.id, props.item)"
                                            >
                                                mdi-file-edit-outline
                                            </v-icon>
                                            Edit
                                        </v-btn>   
                                        <template v-if="$vuetify.breakpoint.xsOnly">
                                            <br>&nbsp;
                                        </template>
                                        <v-btn
                                        elevation="2"
                                            class="mx-2"
                                            dark
                                            outlined
                                            small
                                            color="red"
                                            v-if="$store.getters.valAccess(81) && props.item.name != 'Offset Leave'"
                                            @click="DeleteLeaveType(props.item.id, props.item.name)"
                                        >
                                        <v-icon
                                                small
                                                @click="DeleteLeaveType(props.item.id, props.item.name)"
                                            >
                                                mdi-delete
                                            </v-icon>
                                            Delete
                                        </v-btn>       
                                    </v-row>
                                </template>
                                <template v-else>
                                    <div v-if="$vuetify.breakpoint.mdAndDown">
                                            &nbsp;
                                    </div>
                                    <v-btn
                                    elevation="2"
                                        class="mx-2"
                                        dark
                                        outlined
                                        small
                                        color="orange"
                                        v-if="$store.getters.valAccess(80) && props.item.name != 'Offset Leave'"
                                        @click="UpdateAllLeaveType(props.item.id, props.item)"
                                    >
                                    <v-icon
                                            small
                                            @click="UpdateAllLeaveType(props.item.id, props.item)"
                                        >
                                            mdi-file-edit-outline
                                        </v-icon>
                                        Edit
                                    </v-btn> 
                                    <div v-if="$vuetify.breakpoint.mdAndDown">
                                            &nbsp;
                                    </div>
                                    <v-btn
                                    elevation="2"
                                        class="mx-2"
                                        dark
                                        outlined
                                        small
                                        color="red"
                                        v-if="$store.getters.valAccess(81) && props.item.name != 'Offset Leave'"
                                        @click="DeleteLeaveType(props.item.id, props.item.name)"
                                    >
                                    <v-icon
                                            small
                                            @click="DeleteLeaveType(props.item.id, props.item.name)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                        Delete
                                    </v-btn>    
                                    <div v-if="$vuetify.breakpoint.mdAndDown">
                                            &nbsp;
                                    </div>  
                                </template>   
                            </template>
                      </v-data-table>
                      <v-pagination
                      color="orange"
        v-model="page"
        :length="pageCount"
      ></v-pagination>
                  </v-card-text>
  
              </v-card>
              
          </v-col>
  
      </v-row>
  
        <!-- Dialog Full Screen-->
        <v-dialog
        v-model="fullscreendialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-toolbar
                    dark
                    color="orange"
                >
                    <v-btn icon dark @click="CloseDialogFullScreen()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Add Leave Type</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <!-- <v-btn dark text @click="SaveLeaveType()" id="btn-next" class="btn btn-primary btn-sm p-2" style="font-size: 12px;">
                        Save
                    </v-btn> -->
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
                    <h4>Note: Here you can create leave type</h4>
                </v-col>

                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="leave_type.name"
                        label="Name"
                        color="orange"
                        required
                        outlined
                        :rules="[v => !!v || 'Name is required']"
                    ></v-text-field>
                </v-col>
                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="leave_type.code"
                        label="Code"
                        color="orange"
                        required
                        outlined
                        v-mask="'AAA'"
                        :rules="[v => !!v || 'Code is required']"
                    ></v-text-field>
                </v-col>
                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="leave_type.description"
                        label="Description"
                        required
                        color="orange"
                        outlined
                        :rules="[v => !!v || 'Description is required']"
                    ></v-text-field>
                </v-col>
                <center>
                    <v-col sm="12" md="6">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="valid == false ? validationfalse() : changeStatusDialogSave(true)"
                        >
                        Save
                      </v-btn>
                    </v-col>
                    </center>
                </v-form>
            </v-card>
      </v-dialog>
  
      <!-- Dialog Pop Up Box -->




      <!-- Dialog Full Screen Edit-->
      <v-dialog
        v-model="editfullscreendialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-toolbar
                    dark
                    color="orange"
                >
                    <v-btn icon dark @click="CloseDialogEditFullScreen()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Edit Leave Type</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <!-- <v-btn dark text @click="SaveLeaveType()" id="btn-next" class="btn btn-primary btn-sm p-2" style="font-size: 12px;">
                        Save
                    </v-btn> -->
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
                    <h4>Note: Here you can edit leave type</h4>
                </v-col>

                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="leave_type.name"
                        label="Name"
                        color="orange"
                        required
                        outlined
                        :rules="[v => !!v || 'Name is required']"
                    ></v-text-field>
                </v-col>
                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="leave_type.code"
                        label="Code"
                        color="orange"
                        required
                        outlined
                        v-mask="'AAA'"
                        :rules="[v => !!v || 'Code is required']"
                    ></v-text-field>
                </v-col>
                <v-col sm="12" md="6" offset-md="3"> 
                    <v-text-field
                        v-model="leave_type.description"
                        label="Description"
                        required
                        color="orange"
                        outlined
                        :rules="[v => !!v || 'Description is required']"
                    ></v-text-field>
                </v-col>
                <center>
                    <v-col sm="12" md="6">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="valid == false ? validationfalse() : changeStatusDialogEdit(true)"
                        >
                        Update
                      </v-btn>
                    </v-col>
                    </center>
                </v-form>
            </v-card>
      </v-dialog>
  
      <!-- Dialog Pop Up Box -->


      <!-- save confirmation dialog -->
    <template>
  <v-row justify="center">
    <v-dialog
      v-model="saveconfirmation"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Save
        </v-card-title>
        <v-card-text>Are you sure you want to save?</v-card-text>
        <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogSave(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="SaveLeaveType()"
            >
            Confirm
          </v-btn>
        </center>
        <br>

      </v-card>
    </v-dialog>
  </v-row>
</template>



     <!-- delete confirmation dialog -->
     <template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteconfirmation"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete
        </v-card-title>
        <v-card-text>Are you sure you want to delete {{ fordeletename }}?</v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="changeStatusDialog(false)"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete()"
          >
            Delete
          </v-btn>
        </v-card-actions> -->
        <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogDelete(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="confirmDelete()"
            >
            Confirm
          </v-btn>
        </center>
        <br>
      </v-card>
    </v-dialog>
  </v-row>
</template>
    <!-- end delete confirmation dialog -->

      <v-row justify="center">
          <v-dialog
            v-model="editconfirmation"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title class="text-h5">
                Edit
              </v-card-title>
              <v-card-text>Are you sure you want to update?</v-card-text>
              <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogEdit(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="confirmUpdate()"
            >
            Confirm
          </v-btn>
        </center>
        <br>    
            </v-card>
          </v-dialog>
        </v-row>
        
    </v-container>
      
  </template>
  
  <script>
  
  import leavetypeservices from '../services/leavetypeservices';
  
    export default {
      name: 'LeaveType',
      data: () => ({
        menucolumn: false,
        exportimport: [{ text: 'Upload', icon: 'mdi-upload-outline', linktrigger: 1 },
          { text: 'Download', icon: 'mdi-download-outline', linktrigger: 2 }],
        classito: 'd-inline-block text-truncate',
        search: '',
        page: 1,
        pageCount: 0,
            valid: false,
            saveconfirmation: false,
            editconfirmation: false,
            deleteconfirmation: false,
            editfullscreendialog: false,
            fordeletename: '',
          date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          dialog: false,
          fullscreendialog: false,
          leave_type: {
              name: '',
              code: '',
              description: ''
          },
          headers:[],
        selectedHeaders: [],
        headersMap: [
          //   {
          //     text: 'Supplier',
          //     align: 'start',
          //     sortable: false,
          //     value: 'name',
          //   },
            { text: 'Name',sortable: true, value: 'name' },
            { text: 'Code',sortable: false, value: 'code' },
            { text: 'Description',sortable: false, value: 'description' },
            { text: 'Created By',sortable: false, value: 'created_by' },
            { text: 'Action',sortable: false, value: 'action' }
          ],
          leavetypedata: [],
      }),
      created(){
          this.$store.state.header = false;
          this.$store.state.footer = false;
          this.$store.state.snackbar.color = 'orange';
          this.FetchAllLeaveType();
          this.headers = Object.values(this.headersMap);
        this.selectedHeaders = this.headers;          
      },
      mounted(){
          this.$store.state.title = "Leave Type";
      },
      computed: {
      //Done to get the ordered headers
      showHeaders () {
        return this.headers.filter(s => this.selectedHeaders.includes(s));
      }
    },      
      methods: {
        FetchAllLeaveType(){
            let payload = {
                parent_id: this.$store.getters.getParentID
                // user_id: this.$store.getters.fetchUserInfo.account_information.is_parent == 1 ? this.$store.getters.fetchUserInfo.account_information.id : this.$store.getters.fetchUserInfo.account_information.parent_id
            };
            leavetypeservices.FetchAllLeaveType(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    // console.log(response.data);
                    this.leavetypedata = response.data.data;
                    // console.log(this.leavetypedata);
                }
            }).catch(error => console.log(error));
        },
        UpdateLeaveType(id, name, code, description, created_by){
            this.forUpdate = {
                id: id,
                name: name,
                code: code,
                description: description,
                created_by: created_by
            }
            this.editconfirmation = true
            console.log(id, name, code, description, created_by);
        },
        UpdateAllLeaveType(id, item){
            console.log(id, item);
            this.leave_type.name = item.name
            this.leave_type.code = item.code
            this.leave_type.description = item.description
            // this.leave_type.created_by =
            this.forAllUpdate = {
                id: id,
                created_by: item.created_by
            }

            this.editfullscreendialog = true
        },
        // confirmUpdate(){
        //     let payload = {
        //         id: this.forUpdate['id'],
        //         name: this.forUpdate['name'],
        //         code: this.forUpdate['code'],
        //         description: this.forUpdate['description'],
        //         created_by: this.forUpdate['created_by'],
        //         parent_user_id: this.$store.getters.getParentID,
        //         flag: "1",
        //     }
        //     leavetypeservices.UpdateLeaveType(payload, this.$store.getters.getAccessToken).catch(error =>{
        //         this.errorDAW = error.response.data.message
        //         // this.errorDAW = error.response.data.errors.code // Specific Error Message
        //         // this.errorDAW = error.response.data.message // Message
        //         // this.errorDAW = error.response.data.errors // All Error
        //         this.$store.state.snackbar.message = error.response.data.message;
        //         this.$store.state.snackbar.show = true;
        //     });

        //     this.$store.state.snackbar.message = 'Leave type has been updated.';
        //     this.$store.state.snackbar.show = true;
        //     this.FetchAllLeaveType();
        //     this.editconfirmation = false
        // },
        confirmUpdate(){
            let val = this.$refs.form.validate();

            if(val){
            let payload = {
                id: this.forAllUpdate['id'],
                name: this.leave_type.name,
                code: this.leave_type.code,
                description: this.leave_type.description,
                created_by: this.forAllUpdate['created_by'],
                parent_user_id: this.$store.getters.getParentID,
                flag: "1",
            }
            console.log(payload)
            leavetypeservices.UpdateLeaveType(payload, this.$store.getters.getAccessToken).then(response => {
                    if(response.data.success){
                        this.$store.state.snackbar.message = 'Leave type has been updated.';
                        this.$store.state.snackbar.show = true;
                        this.editfullscreendialog = false
                        this.ClearLeaveType()
                        this.$refs.form.resetValidation();
                        this.$store.state.snackbar.colors = 'xgreen';
                        this.$store.state.snackbar.icon = "mdi-check-circle-outline";                      
                }else{
                      this.$store.state.snackbar.colors = 'xred';
                      this.$store.state.snackbar.icon = "mdi-alert-outline";                      
                  }

                }).catch(error =>{
                this.errorDAW = error.response.data.message
                this.$store.state.snackbar.message = error.response.data.message;
                this.$store.state.snackbar.show = true;
            });
            }
            this.FetchAllLeaveType();
            this.editconfirmation = false
        },
        changeStatusDialogEdit(status){
            this.editconfirmation = status;
            this.FetchAllLeaveType();
        },
        NewLeaveType(){
            this.fullscreendialog = true
        },
        SaveLeaveType(){

            let val = this.$refs.form.validate();

            if(val){
                let payload = {
                    parent_user_id: this.$store.getters.getParentID,
                    name: this.leave_type['name'],
                    code: this.leave_type['code'],
                    description: this.leave_type['description'],
                    flag: "1",
                    created_by: this.$store.getters.getUserID,
                };
                console.log(payload);
                leavetypeservices.SaveLeaveType(payload, this.$store.getters.getAccessToken).then(response => {
                    if(response.data.success){
                        this.fullscreendialog = false;
                        this.$store.state.snackbar.message = 'Leave type has been created.';
                        this.$store.state.snackbar.show = true;
                        this.FetchAllLeaveType();
                        this.ClearLeaveType();
                        this.$refs.form.resetValidation();
                        this.$store.state.snackbar.colors = 'xgreen';
                    this.$store.state.snackbar.icon = "mdi-check-circle-outline";                      
                }else{
                    this.$store.state.snackbar.colors = 'xred';
                      this.$store.state.snackbar.icon = "mdi-alert-outline";                      
                  }
                }).catch(error =>{ 
                    console.log(error.response.data.errors)
                    this.$store.state.snackbar.message = error.response.data.message;
                    this.$store.state.snackbar.show = true;
                    this.$store.state.snackbar.colors = 'xred';
                      this.$store.state.snackbar.icon = "mdi-alert-outline";     
                });
            }
            this.saveconfirmation = false;
        }, 
        CloseDialogFullScreen(){
            this.ClearLeaveType();
            this.fullscreendialog = false
        },
        CloseDialogEditFullScreen(){
            this.ClearLeaveType();
            this.editfullscreendialog = false
        },
        ClearLeaveType(){
            this.leave_type['name'] = ''
            this.leave_type['code'] = ''
            this.leave_type['description'] = ''
        },
        DeleteLeaveType(id, name){
            this.fordeletename = name
            this.forDelete = {
                id: id
            }
            this.deleteconfirmation = true;
        },
        confirmDelete(){
            let payload = {
                id: this.forDelete['id']
            }
            console.log(payload);
            leavetypeservices.DeleteLeaveType(payload, this.$store.getters.getAccessToken).catch(error =>{
                this.errorDAW = error.response.data.message
                // this.errorDAW = error.response.data.errors.code // Specific Error Message
                // this.errorDAW = error.response.data.message // Message
                // this.errorDAW = error.response.data.errors // All Error
                this.$store.state.snackbar.message = error.response.data.message;
                this.$store.state.snackbar.show = true;
            });

            this.$store.state.snackbar.message = 'Leave type has been deleted.';
            this.$store.state.snackbar.show = true;
            this.deleteconfirmation = false;
            this.FetchAllLeaveType();
            this.$store.state.snackbar.colors = 'xgreen';
                    this.$store.state.snackbar.icon = "mdi-check-circle-outline";                                  
        },
        changeStatusDialogSave(status){
            this.saveconfirmation = status;
        },
        changeStatusDialogDelete(status){
            this.deleteconfirmation = status;
            this.FetchAllLeaveType();
        },
        validationfalse(){
            this.$refs.form.validate()
        },
        inlineblock(){
            this.classito == 'd-inline-block text-truncate' ? this.classito = '' : this.classito = 'd-inline-block text-truncate';
        }
      }
    }
  </script>
  
  <style scoped>
  input[type=checkbox] {
  accent-color: orange;
}
  </style>