<template>

    <v-container>

      <v-row>
        <v-col
          v-for="transactions in total_transactions"
          :key="transactions.title"
          :md="6"
          :sm="6"
          :cols="12"
          :lg="3"
        >
          <v-card
          :color="transactions.color">

            <div v-if="(transactions.value == 'ttransaction')">
              <br>
              <h1><center>{{ ttransaction }}</center></h1>
            </div>
            <div v-if="(transactions.value == 'tcustomer')">
              <br>
              <h1><center>{{ tcustomer }}</center></h1>
            </div>
            <div v-if="(transactions.value == 'profit')">
              <br>
              <h1><center>{{ parseFloat(profit).toFixed(0) }}</center></h1>
            </div>  
            <div v-if="(transactions.value == 'atransaction')">
              <br>
              <h1><center>{{ atransaction }}</center></h1>
            </div>
            <center>
              <b>{{ transactions.title }}</b>
            </center>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          
          </v-card>
        </v-col> 

        <v-col
          v-for="card in cards"
          :key="card.title"
          :md="4"
          :sm="4"
          :cols="12"
          :lg="4"
        >
          <v-card
          :color="card.color">
            <div v-if="(card.value == 'gross')">
              <br>
              <h1 style="float: right; color:white; padding:10px">{{ parseFloat(gross).toFixed(2) }}</h1>
              <br>
              <br>
            </div>
            <div v-if="(card.value == 'net')">
              <br>
              <h1 style="float: right; color:white; padding:10px">{{ parseFloat(net).toFixed(2) }}</h1>
              <br>
              <br>
            </div>
            <div v-if="(card.value == 'cost')">
              <br>
              <h1 style="float: right; color:white; padding:10px">{{ parseFloat(cost).toFixed(2) }}</h1>
              <br>
              <br>
            </div>
              <v-card-title v-text="card.title" class="white--text align-bottom"></v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <!-- <v-toolbar-title>Transaction Count</v-toolbar-title> -->
        </v-col>
        <v-col
          v-for="transaction in transactions"
          :key="transaction.title"
          :md="6"
          :sm="6"
          :cols="12"
          :lg="3"
        >
          <v-card
          :color="transaction.color">

            <div v-if="(transaction.value == 'paused')">
              <br>
              <h1><center>{{ paused }}</center></h1>
            </div>
            <div v-if="(transaction.value == 'voidt')">
              <br>
              <h1><center>{{ voidt }}</center></h1>
            </div>
            <div v-if="(transaction.value == 'backout')">
              <br>
              <h1><center>{{ backout }}</center></h1>
            </div>  
            <div v-if="(transaction.value == 'returned')">
              <br>
              <h1><center>{{ returned }}</center></h1>
            </div>
            <center>
              <b>{{ transaction.title }}</b>
            </center>
            <v-card-actions>
              <v-spacer></v-spacer>

            </v-card-actions>
          
          </v-card>
        </v-col>        
        <v-col cols="12">
          <v-card>
            <v-toolbar>
                  <v-toolbar-title>Fast Moving Product</v-toolbar-title>

                  <v-progress-linear
                    :active="top_ten_product_loading"
                    :indeterminate="top_ten_product_loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                  ></v-progress-linear>

                  <v-spacer></v-spacer>

                  <!-- <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>

                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn> -->
              </v-toolbar>

              <BarChart class="px-5 py-5"/>
          </v-card>
        </v-col>
        <v-col cols="12">
            <v-card>
              <v-toolbar>
                  <v-toolbar-title>Total Payment Summary</v-toolbar-title>

                  <v-progress-linear
                    :active="total_payment_loading"
                    :indeterminate="total_payment_loading"
                    absolute
                    bottom
                    color="deep-purple accent-4"
                  ></v-progress-linear>

                  <v-spacer></v-spacer>

                  <!-- <v-btn icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>

                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn> -->
              </v-toolbar>

              <PieChart class="px-5 py-5"/>
            </v-card>

        </v-col>
      </v-row>

    </v-container>

</template>

<script>

  import BarChart from '../includes/BarChart.vue';
  import PieChart from '../includes/PieChart.vue';
  // import LineChart from '../includes/LineChart.vue';
import dashboardservices from '../services/dashboardservices';

  export default {
    name: 'Dashboard',
    components: {
    BarChart,
    PieChart,
    // LineChart
},
    created(){
        this.$store.state.header = true;
        this.$store.state.footer = true;
        this.$store.state.snackbar.color = 'primary';
        this.FetchTransactionGrossInformation();
        this.FetchTransactionNetInformation();
        this.FetchTransactionCostInformation();
        this.FetchTransactionProfitInformation();
        this.FetchTransactionSettledInformation();
        this.FetchTransactionVoidInformation();
        this.FetchTransactionBackoutInformation();
        this.FetchTransactionTotalInformation();
        this.FetchTransactionTotalCustomerInformation();
        this.FetchTransactionActiveInformation();
        this.FetchTransactionPausedInformation();
        this.FetchTransactionReturnedInformation();
    },
    mounted(){
        this.$store.state.title = "Dashboard";
    },
    data: () => ({
      gross:0,
      net:0,
      cost:0,
      profit:0,
      paused:0,
      backout:0,
      voidt:0,
      returned:0,
      ttransaction:0,
      tcustomer:0,
      atransaction:0,
      cards: [
        { title: 'Gross Sales', value: 'gross', color:"#fb8675", md: 4, sm: 6, lg: 3, cols: 12},
        { title: 'Net Sales', value: 'net', color:"#6bafbc", md: 4, sm: 12, lg: 3, cols: 12 },
        { title: 'Cost Sales', value: 'cost', color:"#f3cf85", md: 4, sm: 12, lg: 3, cols: 12 },
        // { title: 'Profit Sales', value: 'profit', color:"#65cea5", md: 6, sm: 12 },
      ],
      total_transactions:[
      { title: 'Total Transaction', value: 'ttransaction',color:"white", md: 3, sm: 12 },
        { title: 'Total Customer', value: 'tcustomer',color:"white", md: 3, sm: 12 },
        { title: 'Total Earning', value: 'profit',color:"white", md: 3, sm: 12 },
        { title: 'Active Transaction', value: 'atransaction',color:"white", md: 3, sm: 12 },
      ],
      transactions: [
      { title: 'Paused Transactions', value: 'paused', md: 3, sm: 12 },
        { title: 'Canceled Transactions', value: 'backout', md: 3, sm: 12 },
        { title: 'Voided Transactions', value: 'voidt', md: 3, sm: 12 },
        { title: 'Returned Transactions', value: 'returned', md: 3, sm: 12 },
      ],
      total_payment_loading: false,
      top_ten_product_loading: false
    }),
    methods: {
      FetchTransactionGrossInformation(){
        let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionGrossInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.gross = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionNetInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionNetInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.net = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionCostInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionCostInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.cost = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionProfitInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionProfitInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.profit = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionSettledInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionSettledInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.settleds = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionVoidInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionVoidInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.voidt = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionBackoutInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionBackoutInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.backout = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionPausedInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionPausedInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.paused = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionReturnedInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionReturnedInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.returned = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionActiveInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionActiveInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.atransaction = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionTotalInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionTotalInformation(payload, this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.ttransaction = response.data.result;
                  }
              }).catch(error => console.log(error));
          },
          FetchTransactionTotalCustomerInformation(){
            let payload={
          business_branch_id: this.$store.getters.getBranchID
        };
              dashboardservices.fetchTransactionTotalCustomerInformation(payload,this.$store.getters.getAccessToken).then(response => {
                  if(response.data.success){
                      this.tcustomer = response.data.result;
                  }
              }).catch(error => console.log(error));
          }
    },

  }
</script>

<style scoped>
.chart {
  height: 100vh;
}
</style>