<template>
    <v-container style="max-width: 95%; position: relative;" >
      <v-row>
          <v-col cols="12">
              <v-card>
                  <v-card-text>
                    <template>
  <v-toolbar dense style="">
    <!-- <v-overflow-btn
      :items="dropdown_font"
      label="Select font"
      hide-details
      class="pa-0"
        overflow
    ></v-overflow-btn> -->
    <!-- <v-overflow> -->
      <!-- <v-divider vertical></v-divider> -->
      <v-row>
        <v-col cols="12">
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          placeholder="Search"
          hide-details
          color="orange"
          overflow
          solo
          dense
          style="min-width: 60px;"
      ></v-text-field>
      </v-col>
    </v-row>
    <!-- </v-overflow> -->

    <!-- <template v-if="$vuetify.breakpoint.mdAndUp"> -->

      <!-- <v-overflow-btn
        :items="headers"
        editable
        label="Select size"
        hide-details
      ></v-overflow-btn> -->

      <!-- <v-divider vertical></v-divider> -->

      <!-- &nbsp; -->
      <!-- <v-btn> -->
        &nbsp;
        <template  v-if="$vuetify.breakpoint.mdAndUp">
  <div class="text-center">
    <v-menu
      v-model="menucolumn"
      :close-on-content-click="false"
      :nudge-width="60"
      :nudge-left="60"      
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-view-column</v-icon>Column
        </v-btn>
      </template>

      <v-card style="padding-top:10px;min-width:50px;" height="200px" class="scroll">
        <!-- &nbsp;<v-icon>mdi-view-column</v-icon>Column -->
        <v-list>
                <ul>
            <div v-for="(item, index) in headers" :key="index">
              <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
              &nbsp;<label :for="item.text">{{item.text}}</label>
            </div>
          </ul>
        </v-list>

      </v-card>
    </v-menu>
  </div>
</template>

<template  v-if="$vuetify.breakpoint.smAndDown">
  <div class="text-center">
    <v-menu
      v-model="menucolumn"
      :close-on-content-click="false"
      :nudge-width="100"
      :nudge-left="100"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          dark
          v-bind="attrs"
          v-on="on"
        >
        <v-icon>mdi-view-column</v-icon>
        </v-btn>
      </template>

      <v-card style="padding-bottom:10px" height="200px" class="scroll">
        <br>
        &nbsp; &nbsp;
        <b>Column</b>
        <v-list>
                <ul>
            <div v-for="(item, index) in headers" :key="index">
              <input type="checkbox" :id="item.text" :value="item" v-model="selectedHeaders">
              &nbsp;<label :for="item.text">{{item.text}}</label>
            </div>
          </ul>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>
        <!-- </v-btn> -->

      <!-- <v-spacer></v-spacer> -->

      <!-- <v-btn-toggle
        v-model="toggle_multiple"
        color="primary"
        dense
        group
        multiple
      > -->
      &nbsp;
        <!-- insert val access supplier download -->
      <!-- <template v-if="$vuetify.breakpoint.mdAndUp">
        
        <v-btn
          text
          color="orange"
          style="border: thin solid orange;"
          @click="ExportSupplierInformation()"
        >
          <v-icon>mdi-download-outline</v-icon>
        </v-btn>
        &nbsp;
        <v-btn
          text
          color="orange"
          style="border: thin solid orange;"
          @click="uploadfileopen()"
        >
          <v-icon>mdi-upload-outline</v-icon>
        </v-btn>
        </template>

        &nbsp;
        <template v-if="$vuetify.breakpoint.smAndDown">
        
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="orange"
                  dark
                  medium
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in exportimport"
                  :key="index"
                >
                <div v-if="item.linktrigger == 1">
                  <v-list-item-title @click="uploadfileopen()"> <v-icon>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                </div>
                <div v-else>
                  <v-list-item-title @click="ExportSupplierInformation()"> <v-icon>{{ item.icon }}</v-icon>{{ item.text }}</v-list-item-title>
                </div>
                </v-list-item>
              </v-list>
            </v-menu>
        </template> -->


        <!-- <v-btn
          text
          color="orange"
          style="border: thin solid orange;"
        >
          <v-icon>mdi-view-column</v-icon>Column
        </v-btn> -->
       
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
        medium
        dark
          color="orange"
          elevation="2"
          @click="NewLeaveType()" 
          v-if="$store.getters.valAccess(79)"
        >
          <v-icon>mdi-plus</v-icon>Create
        </v-btn>
        </template>

        <template v-if="$vuetify.breakpoint.smAndDown">
        <v-btn
        medium
        dark
          color="orange"
          elevation="2"
          style="padding-left: 12px;padding-right: 12px;"
          @click="NewLeaveType()" 
          v-if="$store.getters.valAccess(79)"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        </template>
        

        

        
        <!-- <v-btn
          :value="3"
          text
        >
          <v-icon>mdi-format-underline</v-icon>
        </v-btn>

        <v-btn
          :value="4"
          text
        >
          <v-icon>mdi-format-color-fill</v-icon>
        </v-btn> -->
      <!-- </v-btn-toggle> -->


    
    <!-- </template> -->
  </v-toolbar>
</template>
                        <!-- <div class="text-right">
                            <v-select v-model="selectedHeaders" :items="headers" label="Select Columns" multiple outlined return-object>
                                <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index < 2">
                                    <span>{{ item.text }}</span>
                                </v-chip>
                                <span v-if="index === 2" class="grey--text caption">(+{{ selectedHeaders.length - 2 }} others)</span>
                                </template>
                            </v-select>                        
                            <div class="col-sm-12">
                                <v-btn elevation="2" outlined dark small color="orange" @click="NewLeaveType()"  v-if="$store.getters.valAccess(205)">
                                    <v-icon small>
                                        mdi-plus
                                    </v-icon>
                                    Create
                                </v-btn>
                            </div>
                        </div>
                        <div class="offset-md-9 col-sm-3">
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                outlined
                                hide-details
                                color="orange"
                            ></v-text-field>
                        </div> -->
                        <br>
                        <v-data-table
                        :headers="showHeaders"
                        :items="userchecklistdata"
                        :search="search" 
                        :page.sync="page"
                        @page-count="pageCount = $event"
                        :footer-props="{
                      'items-per-page-options':[10],
                      'disable-items-per-page': true,
    }"
                        >
                            <!-- <template v-slot:item.created_by="props">
                                {{ props.item.created_info != null ? props.item.created_info.personal_information.lastname + ' , ' + props.item.created_info.personal_information.firstname : 'No Data' }}
                            </template> -->

                            <template v-slot:[checklistdatacell(b)]="props" v-for="(b,counter, countersss) in templateheaders" >
                                <template>
                                        <!-- {{props['item']['checklist'][checklistcell(b)] != null ? props['item']['checklist'][checklistcell(b)]['checkbox'] : ''}}
                                        {{props['item']['requirement'][checklistcell(b)] != null ? props['item']['requirement'][checklistcell(b)]['checkbox'] : ''}} -->
                                        <div :key="counter" v-if="props['item']['checklist'][checklistcell(b)] != null">
                                            <v-checkbox 
                                                v-model="props['item']['checklist'][checklistcell(b)]['checkbox']"
                                                readonly
                                                v-if="props['item']['checklist'][checklistcell(b)]['checkbox'] == 'true'"
                                                color="orange"
                                            ></v-checkbox> 
                                            <v-checkbox 
                                                v-else
                                                value="false"
                                                readonly
                                                color="orange"
                                            ></v-checkbox>  
                                        </div>
                                        <div v-else :key="countersss">
                                            <div v-if="props['item']['requirement'][checklistcell(b)] != null">
                                                <v-checkbox 
                                                    v-model="props['item']['requirement'][checklistcell(b)]['checkbox']"
                                                    readonly
                                                    v-if="props['item']['requirement'][checklistcell(b)]['checkbox'] == 'true'"
                                                    color="orange"
                                                ></v-checkbox> 
                                                <v-checkbox 
                                                    v-else
                                                    value="false"
                                                    readonly
                                                    color="orange"
                                                ></v-checkbox>  
                                            </div>
                                            <div v-else>
                                                <v-checkbox 
                                                color="orange"
                                                readonly
                                                value="false"
                                            ></v-checkbox>  
                                            </div>
                                        </div>
                                </template>
                            </template>

                            <template v-slot:item.action="props">

                              <v-btn
                                elevation="2"
                                    class="mx-2"
                                    dark
                                    outlined
                                    small
                                    color="orange"
                                    v-if="$store.getters.valAccess(206) && props.item.name != 'Offset Leave'"
                                    @click="UpdateAllUserchecklist(props.item.id, props.item)"
                                >
                                <v-icon
                                        small
                                        @click="UpdateAllUserchecklist(props.item.id, props.item)"
                                    >
                                        mdi-file-edit-outline
                                    </v-icon>
                                    Edit
                                </v-btn>
                                <v-btn
                                elevation="2"
                                    class="mx-2"
                                    dark
                                    outlined
                                    small
                                    color="red"
                                    v-if="$store.getters.valAccess(207) && props.item.name != 'Offset Leave'"
                                    @click="DeleteChecklist(props.item.id, props.item.name)"
                                >
                                <v-icon
                                        small
                                        @click="DeleteChecklist(props.item.id, props.item.name)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                    Delete
                                </v-btn>       
                                      
                            </template>
                        </v-data-table>
                        <v-pagination
                        color="orange"
                        v-model="page"
                        :length="pageCount"
                        ></v-pagination>

                        <!-- <v-data-table
                        :headers="headerchecklist"
                        :items="userchecklistdata" 
                        >
                        </v-data-table> -->
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
  
        <!-- Dialog Full Screen-->
        <v-dialog
        v-model="fullscreendialog"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-toolbar
                    dark
                    color="orange"
                >
                    <v-btn icon dark @click="CloseDialogFullScreen()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Add Onboarding</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <!-- <v-btn dark text @click="SaveChecklist()" id="btn-next" class="btn btn-primary btn-sm p-2" style="font-size: 12px;">
                        Save
                    </v-btn> -->
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
                    <h4>Note: Here you can add Onboarding User here</h4>
                </v-col>

                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-left">
                    <!-- <v-text-field
                        required
                        color="orange"
                        outlined
                        label="Employee Id"
                        v-model="employeeid"
                    ></v-text-field> -->
                    <div class="sm-12">
                        <v-combobox
                            outlined
                            color="orange"
                            v-model="user_info"
                            label="Employee"
                            :clearable="this.user_info != ''"
                            @change="clickuser()"
                            :items="listemployeedata"
                        ></v-combobox>
                    </div>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field
                                required
                                color="orange"
                                outlined
                                label="First Name"
                                v-model="firstname"
                                :rules="[v => !!v || 'First Name is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field
                                required
                                color="orange"
                                outlined
                                label="Middle Name"
                                v-model="middlename"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field
                                required
                                color="orange"
                                outlined
                                label="Last Name"
                                :rules="[v => !!v || 'Last Name is required']"
                                v-model="lastname"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-left"> 
                    <h3>Checklist</h3>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" v-for="(item, counter) in checklistdata" v-bind:key="item.name + counter"> 
                    <v-row>
                        <v-col sm="1" class="text-right">
                            <v-checkbox
                                required
                                color="orange"
                                outlined
                                v-model="checklistcheckboxdata[counter]"
                            ></v-checkbox>
                        </v-col>
                        <v-col sm="5" class="mt-5 text-left">
                            <input type="hidden" :v-model="checklistnamedata[counter] = item.name" />
                            <h4>{{item.name}}</h4>
                        </v-col>
                        <v-col sm="5" class="text-center">
                            <v-file-input 
                                hint="File Upload"
                                persistent-hint
                                prepend-icon="mdi-file"
                                :value="current"
                                v-model="checklistfiledata[counter]"
                                @change="changechangelistto(counter)"
                            >
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-left"> 
                    <h3>Requirement</h3>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" v-for="(items, counters) in requirementdata" v-bind:key="items.name + counters"> 
                    <v-row>
                        <v-col sm="1" class="text-right">
                            <v-checkbox
                                required
                                color="orange"
                                outlined
                                v-model="requirementcheckboxdata[counters]"
                            ></v-checkbox>
                        </v-col>
                        <v-col sm="5" class="mt-5 text-left">
                            <input type="hidden" :v-model="requirementnamedata[counters] = items.name" />
                            <h4>{{items.name}}</h4>
                        </v-col>
                        <v-col sm="5" class="text-center">
                            <v-file-input 
                                hint="File Upload"
                                persistent-hint
                                prepend-icon="mdi-file"
                                v-model="requirementfiledata[counters]"
                                @change="changerequirelistto(counters)"
                            >
                            </v-file-input>
                        </v-col>
                    </v-row>
                </v-col>
                <center>
                    <v-col sm="12" md="6">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="valid == false ? validationfalse() : changeStatusDialogSave(true)"
                        >
                        Save
                      </v-btn>
                    </v-col>
                    </center>
                </v-form>
            </v-card>
      </v-dialog>
  
      <!-- Dialog Pop Up Box -->


      <!-- Dialog Full Screen Edit-->



















      <v-dialog
        v-model="editfullscreendialog"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                <v-toolbar
                    dark
                    color="orange"
                >
                    <v-btn icon dark @click="CloseDialogEditFullScreen()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Edit Onboarding</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                    <!-- <v-btn dark text @click="SaveChecklist()" id="btn-next" class="btn btn-primary btn-sm p-2" style="font-size: 12px;">
                        Save
                    </v-btn> -->
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-center"> 
                    <h4>Note: Here you can edit Onboarding User here</h4>
                </v-col>

                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-left">
                    <div class="sm-12">
                        <v-combobox
                            outlined
                            color="orange"
                            v-model="user_info"
                            label="Employee"
                            :clearable="this.user_info != ''"
                            @change="clickuser()"
                            :items="listemployeedata"
                        ></v-combobox>
                    </div>
                    <v-row>
                        <v-col sm="4">
                            <v-text-field
                                required
                                color="orange"
                                outlined
                                label="First Name"
                                v-model="firstname"
                                :rules="[v => !!v || 'First Name is required']"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field
                                required
                                color="orange"
                                outlined
                                label="Middle Name"
                                v-model="middlename"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="4">
                            <v-text-field
                                required
                                color="orange"
                                outlined
                                label="Last Name"
                                :rules="[v => !!v || 'Last Name is required']"
                                v-model="lastname"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-left"> 
                    <h3>Checklist</h3>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" v-for="(item, counter) in checklistdata" v-bind:key="item.name + counter"> 
                    <v-row>
                        <v-col sm="1" class="text-right">
                            <v-checkbox
                                required
                                color="orange"
                                outlined
                                :value="linkTypeCheck"
                                v-model="checklistcheckboxdata[counter]"
                            ></v-checkbox>
                        </v-col>
                        <v-col sm="5" class="mt-5 text-left">
                            <input type="hidden" :v-model="checklistnamedata[counter] = item.name" />
                            <h4>{{item.name}}</h4>
                        </v-col>
                        <v-col sm="5" class="text-center">
                            <v-file-input 
                                hint="File Upload"
                                persistent-hint
                                prepend-icon="mdi-file"
                                v-model="checklistfiledata[counter]"
                                @change="changechangelistto(counter)"
                            >
                            </v-file-input>
                        </v-col>
                        <div sm="1" class="mt-10" v-if="checklistfiledatapath[counter] != null">
                            <a href="#" @click.prevent="getfile(checklistfiledatapath[counter])">View</a>
                        </div>
                    </v-row>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" class="mt-3 text-left"> 
                    <h3>Requirement</h3>
                </v-col>
                <v-col sm="12" md="6" offset-md="3" v-for="(items, counters) in requirementdata" v-bind:key="items.name + counters"> 
                    <v-row>
                        <v-col sm="1" class="text-right">
                            <v-checkbox
                                required
                                color="orange"
                                outlined
                                :value="linkType"
                                v-model="requirementcheckboxdata[counters]"
                            ></v-checkbox>
                        </v-col>
                        <v-col sm="5" class="mt-5 text-left">
                            <input type="hidden" :v-model="requirementnamedata[counters] = items.name" />
                            <h4>{{items.name}}</h4>
                        </v-col>
                        <v-col sm="5" class="text-center">
                            <v-file-input 
                                hint="File Upload"
                                persistent-hint
                                prepend-icon="mdi-file"
                                v-model="requirementfiledata[counters]"
                                @change="changerequirelistto(counters)"
                            >
                            </v-file-input>
                        </v-col>
                        <div sm="1" class="mt-10" v-if="requirementfiledatapath[counters] != null">
                            <a href="#" @click.prevent="getfile(requirementfiledatapath[counters])">View</a>
                        </div>
                    </v-row>
                </v-col>
                <center>
                    <v-col sm="12" md="6">
                        <v-btn
                        color="orange"
                        block
                        outlined
                        @click="valid == false ? validationfalse() : changeStatusDialogEdit(true)"
                        >
                        Update
                      </v-btn>
                    </v-col>
                    </center>
                </v-form>
            </v-card>
      </v-dialog>
  
      <!-- Dialog Pop Up Box -->
















      


      <!-- save confirmation dialog -->
    <template>
  <v-row justify="center">
    <v-dialog
      v-model="saveconfirmation"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Save
        </v-card-title>
        <v-card-text>Are you sure you want to save?</v-card-text>
        <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogSave(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="SaveChecklist()"
            >
            Confirm
          </v-btn>
        </center>
        <br>

      </v-card>
    </v-dialog>
  </v-row>
</template>



     <!-- delete confirmation dialog -->
     <template>
  <v-row justify="center">
    <v-dialog
      v-model="deleteconfirmation"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete
        </v-card-title>
        <v-card-text>Are you sure you want to delete {{ fordeletename }}?</v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="changeStatusDialog(false)"
          >
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete()"
          >
            Delete
          </v-btn>
        </v-card-actions> -->
        <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogDelete(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="confirmDelete()"
            >
            Confirm
          </v-btn>
        </center>
        <br>
      </v-card>
    </v-dialog>
  </v-row>
</template>
    <!-- end delete confirmation dialog -->

      <v-row justify="center">
          <v-dialog
            v-model="editconfirmation"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title class="text-h5">
                Edit
              </v-card-title>
              <v-card-text>Are you sure you want to update?</v-card-text>
              <center>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="red"
            @click="changeStatusDialogEdit(false)"
            >
                Cancel
          </v-btn>
          <v-btn
            elevation="2"
            class="mx-2"
            dark
            outlined
            small
            color="orange"
            @click="confirmUpdate()"
            >
            Confirm
          </v-btn>
        </center>
        <br>    
            </v-card>
          </v-dialog>
        </v-row>
        
    </v-container>
      
  </template>
  
  <script>
  
  import userchecklistservices from '../services/userchecklistservices';
  import checklistservices from '../services/checklistservices';
  import userservices from '../services/userservices';
  
    export default {
      name: 'Userchecklist',
      data: () => ({
        menucolumn: false,
        listemployeedata: [],
        user_info: [],
        linkType: 'true',
        linkTypeCheck: 'true',
        hahahaha: '',
        hamehame: 'Job_Offer',
        current: '',
        triggerto: 0,
        employeeid: 0,
        firstname: '',
        middlename: '',
        lastname: '',
        search: '',
        page: 1,
        pageCount: 0,
        valid: false,
        saveconfirmation: false,
        editconfirmation: false,
        deleteconfirmation: false,
        editfullscreendialog: false,
        fordeletename: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dialog: false,
        fullscreendialog: false,
        checklisttypeitems : ['Checklist', 'Requirement'],
        checklist: {
            name: '',
            type: ''
        },
        headers:[],
        selectedHeaders: [],
        headersMap: [
            { text: 'First Name',sortable: true, value: 'firstname' },
            { text: 'Middle Name',sortable: false, value: 'middlename' },
            { text: 'Last Name',sortable: false, value: 'lastname' },
            { text: 'Checklist',sortable: false, value: 'checklist' },
            { text: 'Requirement',sortable: false, value: 'requirement' },
            // { text: 'Created By',sortable: false, value: 'created_by' },
            { text: 'Action',sortable: false, value: 'action' }
        ],
        headerchecklist: [
            { text: 'First Name',sortable: true, value: 'firstname' },
            { text: 'Middle Name',sortable: false, value: 'middlename' },
            { text: 'Last Name',sortable: false, value: 'lastname' },
            // { text: 'Created By',sortable: false, value: 'created_by' },
            // { text: 'Action',sortable: false, value: 'action' }
        ],

        userchecklistdata: [],

        checklistdatalength: '',
        checklistdata: [],
        checklistcheckboxdata: [],
        checklistfiledata: [],
        checklistnamedata: [],
        finaldatachecklist: [],
        subfinaldatachecklist: [],

        requirementdatalength: '',
        requirementdata: [],
        requirementcheckboxdata: [],
        requirementfiledata: [],
        requirementnamedata: [],
        finaldatarequirement: [],
        subfinaldatarequirement: [],

        templateheaders:[],
        parasaprops:[],

        checkname:'',
        reqname:'',
        image_path: [],
        requirementfiledatapath: [],
        checklistfiledatapath: [],
      }),
      created(){
        this.$store.state.header = false;
        this.$store.state.footer = false;
        this.$store.state.snackbar.color = 'orange';
        this.FetchAllUserchecklist();
        this.FetchAllbyTypeChecklist();
        this.FetchAllbyTypeRequirement();
        this.FetchAllUser();
        this.headers = Object.values(this.headersMap);
        this.selectedHeaders = this.headers;          
      },
      mounted(){
          this.$store.state.title = "User Onboarding";
      },
      computed: {
      //Done to get the ordered headers
      showHeaders () {
        return this.headers.filter(s => this.selectedHeaders.includes(s));
      }
    },      
      methods: {
        FetchAllUserchecklist(){
            let payload = {
                parent_id: this.$store.getters.getParentID
                // user_id: this.$store.getters.fetchUserInfo.account_information.is_parent == 1 ? this.$store.getters.fetchUserInfo.account_information.id : this.$store.getters.fetchUserInfo.account_information.parent_id
            };
            userchecklistservices.FetchAllUserchecklist(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    
                    this.templateheaders = response.data.headers;
                    
                    this.userchecklistdata = response.data.data;
                    this.image_path = response.data.image_path
                    // console.log(this.userchecklistdata);

                    if(this.triggerto == 0){
                        response.data.headers.forEach((value) => {
                            this.headerchecklist.push({
                                text: value.text,
                                value: value.value,
                                width: '120px',
                                sortable: false
                            });
                        });
                        this.headerchecklist.push({
                            text: 'Action',
                            value: 'action'
                        });
                        this.headers = Object.values(this.headerchecklist);
                        this.selectedHeaders = this.headers;
                        this.triggerto = 1;
                    }       
                    console.log(this.headerchecklist);
                }
            }).catch(error => console.log(error));
        },
        FetchAllbyTypeChecklist(){
            let payload = {
                parent_id: this.$store.getters.getParentID,
                type: 'Checklist'
            };
            checklistservices.FetchAllbyType(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    this.checklistdata = response.data.data;
                    this.checklistdatalength = this.checklistdata.length;
                    // console.log(this.checklistdatalength);
                }
            }).catch(error => console.log(error));
        },
        FetchAllbyTypeRequirement(){
            let payload = {
                parent_id: this.$store.getters.getParentID,
                type: 'Requirement'
            };
            checklistservices.FetchAllbyType(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    this.requirementdata = response.data.data;
                    this.requirementdatalength=this.requirementdata.length;
                    // console.log(this.requirementdata);
                }
            }).catch(error => console.log(error));
        },
        UpdateChecklist(id, name, code, description, created_by){
            this.forUpdate = {
                id: id,
                name: name,
                code: code,
                description: description,
                created_by: created_by
            }
            this.editconfirmation = true
            console.log(id, name, code, description, created_by);
        },
        confirmUpdate(){
            let loader = this.$loading.show();
            let val = this.$refs.form.validate();

            if(val){
                const formData = new FormData();
                formData.append('id', this.forAllUpdate.id);
                formData.append('user_id', this.employeeid)
                formData.append('parent_user_id', this.$store.getters.getParentID)
                formData.append('firstname', this.firstname)
                formData.append('lastname', this.lastname)
                formData.append('middlename', this.middlename == null ? '' : this.middlename)
                // console.log(formData.getAll('middlename'))
                for (var i = 0 ; i < this.requirementdatalength ; i++){
                    this.finaldatarequirement.push(this.subfinaldatarequirement);
                    formData.append('requirements[]', this.requirementcheckboxdata[i]);
                    formData.append('requirements[]', this.requirementnamedata[i]);    
                    formData.append('requirementfile'+ i, this.requirementfiledata[i]);
                    // console.log(formData.getAll('requirementfile'+ i))
                }

                for (var y = 0 ; y < this.checklistdatalength ; y++){
                    formData.append('checklists[]', this.checklistcheckboxdata[y]);
                    formData.append('checklists[]', this.checklistnamedata[y]);    
                    formData.append('checklistfile'+ y, this.checklistfiledata[y]);
                    // console.log(formData.getAll('checklistfile'+ y))
                }

                userchecklistservices.UpdateUserchecklist(formData, this.$store.getters.getAccessToken).then(response => {
                        if(response.data.success){
                            loader.hide();
                            this.$store.state.snackbar.message = 'User Onboarding has been updated.';
                            this.$store.state.snackbar.show = true;
                            this.editfullscreendialog = false
                            this.FetchAllUserchecklist();
                            this.ClearChecklist();
                            this.$refs.form.resetValidation();
                        }
                    }).catch(error =>{
                    loader.hide();
                    this.errorDAW = error.response.data.message
                    this.$store.state.snackbar.message = error.response.data.message;
                    this.$store.state.snackbar.show = true;
                });
            }
            // this.FetchAllUserchecklist();
            this.editconfirmation = false
        },
        changeStatusDialogEdit(status){
            this.editconfirmation = status;
            this.FetchAllUserchecklist();        
        },
        NewLeaveType(){
            this.ClearChecklist();
            this.fullscreendialog = true
        },
        SaveChecklist(){
            let val = this.$refs.form.validate();
            if(val){

            let loader = this.$loading.show();
                const formData = new FormData();
                for (var i = 0 ; i < this.requirementdatalength ; i++){
                    this.subfinaldatarequirement = { 
                        checkbox:this.requirementcheckboxdata[i], 
                        name:this.requirementnamedata[i], 
                        file:this.requirementfiledata[i]
                    }
                    this.finaldatarequirement.push(this.subfinaldatarequirement);

                    // formData.append('checkbox', this.requirementcheckboxdata[i]);
                    // formData.append('name', this.requirementnamedata[i]);    
                    // formData.append('file', this.requirementfiledata[i]);
                    formData.append('requirements[]', this.requirementcheckboxdata[i]);
                    formData.append('requirements[]', this.requirementnamedata[i]);    
                    formData.append('requirementfile'+ i, this.requirementfiledata[i]);
                }

                for (var y = 0 ; y < this.checklistdatalength ; y++){
                    this.subfinaldatachecklist = { 
                        checkbox:this.checklistcheckboxdata[y], 
                        name:this.checklistnamedata[y], 
                        file:this.checklistfiledata[y]
                    }
                    this.finaldatachecklist.push(this.subfinaldatachecklist);

                    formData.append('checklists[]', this.checklistcheckboxdata[y]);
                    formData.append('checklists[]', this.checklistnamedata[y]);    
                    formData.append('checklistfile'+ y, this.checklistfiledata[y]);
                }
                // console.log(this.finaldatarequirement)

                // for (var x = 0 ; x < this.checklistdatalength ; x++){
                //     this.subfinaldatachecklist = { 
                //         checkbox:this.checklistcheckboxdata[x], 
                //         name:this.checklistnamedata[x], 
                //         file: this.checklistfiledata[x]
                //     }
                //     this.finaldatachecklist.push(this.subfinaldatachecklist);
                // }

                // console.log('datachecklistto')
                // console.log(this.requirementfiledata[0]);

                formData.append('firstname', this.firstname);
                formData.append('middlename', this.middlename);    
                formData.append('lastname', this.lastname);
                // formData.append('requirement', this.finaldatarequirement)
                formData.append('user_id', this.employeeid)
                formData.append('parent_user_id', this.$store.getters.getParentID)
                formData.append('created_by', this.$store.getters.getUserID)

                // console.log(formData.getAll('checkbox'));
                // console.log(formData.getAll('name'));
                // console.log(requirements[][1]);
                userchecklistservices.SaveUserchecklist(formData, this.$store.getters.getAccessToken).then(response => {
                        if(response.data.success){
                            loader.hide();
                            this.fullscreendialog = false;
                            this.$store.state.snackbar.message = 'Onboarding has been created.';
                            this.$store.state.snackbar.show = true;
                            this.FetchAllUserchecklist();
                            this.ClearChecklist();
                            this.$refs.form.resetValidation();
                        }
                    }).catch(error =>{
                    loader.hide();
                    this.$store.state.snackbar.message = error.response.data.message;
                    this.$store.state.snackbar.show = true;
                });

            }
            this.saveconfirmation = false;
            
        }, 
        CloseDialogFullScreen(){
            this.ClearChecklist();
            this.fullscreendialog = false
        },
        CloseDialogEditFullScreen(){
            this.ClearChecklist();
            this.editfullscreendialog = false
        },
        ClearChecklist(){
            this.firstname = ''
            this.middlename = ''
            this.lastname = ''
            // this.checklistdatalength= ''
            this.checklistcheckboxdata= []
            this.checklistfiledata= []
            this.checklistnamedata= []
            this.finaldatachecklist= []
            this.subfinaldatachecklist= []
            // this.requirementdatalength= ''
            this.requirementcheckboxdata= []
            this.requirementfiledata= []
            this.requirementnamedata= []
            this.finaldatarequirement= []
            this.subfinaldatarequirement= []
            this.requirementfiledatapath= []
            this.checklistfiledatapath= []
            this.user_info=[]
        },
        DeleteChecklist(id, name){
            this.fordeletename = name
            this.forDelete = {
                id: id
            }
            this.deleteconfirmation = true;
        },
        confirmDelete(){
            let payload = {
                id: this.forDelete['id']
            }
            console.log(payload);
            userchecklistservices.DeleteUserchecklist(payload, this.$store.getters.getAccessToken).catch(error =>{
                this.$store.state.snackbar.message = error.response.data.message;
                this.$store.state.snackbar.show = true;
            });

            this.$store.state.snackbar.message = 'User Onboarding has been deleted.';
            this.$store.state.snackbar.show = true;
            this.deleteconfirmation = false;
            this.FetchAllUserchecklist();
        },
        changeStatusDialogSave(status){
            this.saveconfirmation = status;
        },
        changeStatusDialogDelete(status){
            this.deleteconfirmation = status;
            this.FetchAllUserchecklist();
        },
        validationfalse(){
            this.$refs.form.validate()
        },
        changechangelistto(haha){
            // console.log( this.checklistcheckboxdata[haha])
            this.checklistcheckboxdata[haha] = true
            if(this.checklistfiledata[haha] == null){
                this.checklistcheckboxdata[haha] = false
            }
        },
        changerequirelistto(haha){
          // console.log(this.requirementcheckboxdata[haha])
            this.requirementcheckboxdata[haha] = true
            if(this.requirementfiledata[haha] == null){
                this.requirementcheckboxdata[haha] = false
            }
        },
        UpdateAllUserchecklist(id, item){
            console.log(id, item);
            this.firstname = item.firstname
            this.middlename = item.middlename
            this.lastname = item.lastname

            this.user_info = {
                    text: item.middlename == '' ? item.lastname + ', ' + item.firstname : item.lastname + ', ' + item.firstname + ', ' + item.middlename,
                    firstname: item.firstname,
                    lastname: item.lastname,
                    middlename: item.middlename == null ? '' : item.middlename,
                    // employee_id: value.user_other_personal_information.employee_id == null ? '' : value.user_other_personal_information.employee_id,
                    value: item.user_id
                }
            

            for (var y = 0 ; y < this.checklistdatalength ; y++){
                this.checkname = this.checklistdata[y].name.replaceAll(' ', '_')
                const object = item.checklist;
                for (const property in object) {
                    // console.log(`${property}: ${object[property]}`);
                    // console.log(`${object[property]['name']}`);
                    if(this.checkname == `${property}`){
                       this.checklistcheckboxdata[y] = `${object[property]['checkbox']}`
                    if(`${object[property]['file']}` != ''){
                       this.checklistfiledata[y] =  new File(["foo"], `${object[property]['file']}`)
                    //    console.log(this.checklistfiledata[y].name)
                    this.checklistfiledatapath[y] = this.checklistfiledata[y].name
                        }
                    }
                }
               
            }

            for (var z = 0 ; z < this.requirementdatalength ; z++){
                this.reqname = this.requirementdata[z].name.replaceAll(' ', '_')
                const object = item.requirement;
                for (const property in object) {
                    // console.log(`${property}: ${object[property]}`);
                    // console.log(`${object[property]['name']}`);
                    if(this.reqname == `${property}`){
                       this.requirementcheckboxdata[z] = `${object[property]['checkbox']}`
                    if(`${object[property]['file']}` != ''){
                       this.requirementfiledata[z] =  new File(["foo"], `${object[property]['file']}`)
                    //    console.log(this.requirementfiledata[z].name)
                       this.requirementfiledatapath[z] = this.requirementfiledata[z].name
                        }
                    }
                }
               
            }
            
            this.forAllUpdate = {
                id: id
            }

            this.editfullscreendialog = true
        },
        checklistdatacell(key) {
            // console.log(`item.${key['value']}`)
            return `item.${key['value']}`; // simple string interpolation
        },
        checklistcell(key) {
            // console.log(key.text.replaceAll(' ', '_'))
            return key.text.replaceAll(' ', '_'); // simple string interpolation
        },
        getfile(file) {
            // alert(file);
            window.open(this.image_path + file, "_blank");
        },
        FetchAllUser(){
            let payload = {
                parent_id: this.$store.getters.getParentID
            };
            userservices.FetchAllUser(payload, this.$store.getters.getAccessToken).then(response => {
                if(response.data.success){
                    // this.listemployeedata = response.data.data;
                    console.log(response.data.data);
                    response.data.data.forEach((value) => {
                        value.personal_information.middlename == null ? value.personal_information.middlename = '' : value.personal_information.middlename
                        value.user_other_personal_information.employee_id == null ? value.user_other_personal_information.employee_id = '' : value.user_other_personal_information.employee_id
                        this.listemployeedata.push({
                            // text: value.user_other_personal_information.employee_id + ' : ' + value.personal_information.lastname + ', ' + value.personal_information.firstname + ', ' + value.personal_information.middlename,
                            text: value.personal_information.middlename == '' ? value.personal_information.lastname + ', ' + value.personal_information.firstname : value.personal_information.lastname + ', ' + value.personal_information.firstname + ', ' + value.personal_information.middlename,
                            firstname: value.personal_information.firstname,
                            lastname: value.personal_information.lastname,
                            middlename: value.personal_information.middlename == null ? '' : value.personal_information.middlename,
                            employee_id: value.user_other_personal_information.employee_id == null ? '' : value.user_other_personal_information.employee_id,
                            value: value.id
                        });
                    });
                    // console.log(this.listemployeedata);
                }
            }).catch(error => console.log(error));
        },
        clickuser(){
            // console.log(this.user_info);
            // console.log(this.listemployeedata);
            console.log(this.user_info)
            console.log('daan')
            this.firstname = this.user_info != null ? this.user_info.firstname : '' 
            this.middlename = this.user_info != null ? this.user_info.middlename : ''
            this.lastname = this.user_info != null ? this.user_info.lastname : ''
            this.employeeid = this.user_info != null ? this.user_info.value : 0
        }
      }
    }
  </script>
  
  <style scoped>
   input[type=checkbox] {
  accent-color: orange;
}
  </style>